import { IFasSolution, IJobData, createFasteningFluidJobDataReq } from './types';

export const getJobDataToBack = (body: createFasteningFluidJobDataReq): IJobData => ({
  index: body.index ?? 0,
  fluid: body.fluid ?? '',
  well_uuid: body.well_uuid ?? '',
  flow_rate: body.flow_rate ?? 0,

  calc_type: body.calc_type,
  duration: body.duration,
  volume: body.volume,
  length: body.length,
  top: body.top
});

export const getSolutionToBack = (body: createFasteningFluidJobDataReq): IFasSolution => ({
  item_meta: body.item_meta,
  item_data: body.item_data,
  job_data_uuid: body.job_data_uuid ?? '',
  
  fluid: body.fluid,
  n: body.n ?? 0,
  K: body.K ?? 0,
  density: body.density ?? 0,
  yield_point: body.yield_point ?? 0,
  plastic_viscosity: body.plastic_viscosity ?? 0,

  base: body.base ?? '',
  rheology: body.rheology,
  well_uuid: body.well_uuid ?? '',
  base_type: body.base_type ?? '',
  fluid_type: body.fluid_type ?? ''
});

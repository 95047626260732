import request from '../../utils/http/request';
import options from './options';
import { getUrl } from '../utils';
import {
  CloseIncidentType,
  CriticalityType, FactIncident, IncidentInterval, IncidentResult,
  IncidentSet, IncidentSubstance, IncidentType, IResponseType, PlanIncident, UnitOfMeasureType, UnknownIncident
} from './types';

const { apiUrl, ddUrl } = options;

const url = getUrl(apiUrl);
const ddApiUrl = getUrl(ddUrl);

export const getResponseTypeApi = async ():Promise<IResponseType[]> => request.get(url('incident/hseresponsetype'));

export const getIncidentSetApi = async (): Promise<IncidentSet[]> => request.get(url('incident/incidentset'));

export const getIncidentTypeApi = async (): Promise<IncidentType[]> => request.get(url('incident/incident-type'));

export const getIncidentSubstanceApi = async (): Promise<IncidentSubstance[]> => request.get(url('incident/r_incident_substance'));

export const getCriticalityTypeApi = async (): Promise<CriticalityType[]> => request.get(url('incident/criticality_type'));

export const getIncidentResultApi = async (): Promise<IncidentResult> => request.get(url('incident/incidentrespresult'));

export const createFactIncidentApi = async (body: any): Promise<FactIncident> => request.post(url('incident'), body);

export const createPlanIncidentApi = async (body: unknown): Promise<PlanIncident> => request.post(url('incident'), body);

export const getIncidentByWellIdApi = async (id: string): Promise<UnknownIncident[]> => request.get(ddApiUrl(`${id}`));

export const getIncidentFactByWellIdApi = async (id: string): Promise<UnknownIncident[]> => request.get(ddApiUrl(`/findAllFactIncidentsByWellId/${id}`));

export const deleteIncidentApi = async (id: string): Promise<{ incidentId: string }> => request.delete(url(`incident/${id}`));

export const liquidateIncidentApi = async (body: unknown): Promise<FactIncident> => request.put(url('incident/updateFactIncidentById'), body);

export const closeIncidentApi = async (body: CloseIncidentType): Promise<unknown> => request.put(url('incident/closeFactIncidentById'), body);

export const editPlanIncidentApi = async (body: unknown): Promise<FactIncident> => request.put(url('incident/updatePlanIncidentById'), body);

export const getUnitOfMeasureApi = (): Promise<UnitOfMeasureType[]> => request.get(url('incident/unitofmeasure'));

export const getIncidentByIntervalApi = (body: IncidentInterval): Promise<PlanIncident[]> => request.post(url('incident/findAllPlanIncidentByInterval'), body);

export const deleteAllPlanIncidentsApi = (wellId: string): Promise<void> => request.delete(ddApiUrl(wellId));

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {
  FacilityFormType, FacilityInfoFormType, FacilitySummaryResponseType, FacilityType
} from './types';
import {
  createFacilityLinkedToFieldAndArea,
  createOrUpdateProjectSecondStep,
  getFacilities,
  getFacilitiesForm, getFacility2StepSummary,
  getFacilityDetail,
  getFacilityDetailMnemonics
} from './actions';
import {
  facility2StepSummary2Client, facilityDetail2Client, facilityFormList2Client
} from './converters';

const { name } = options;

type FacilityState = {
    isLoading: boolean
    facilities: FacilityType[],
    facilitiesMnemonics: FacilityType[],
    error: string,
    facilitiesForm: FacilityFormType[],
    facilityInfo: FacilityInfoFormType | null
};

const initialState: FacilityState = {
  isLoading: false,
  facilities: [],
  facilitiesMnemonics: [],
  error: '',
  facilitiesForm: [],
  facilityInfo: null
};

export const facilitySlice = createSlice({
  name,
  initialState,
  reducers: {
    changeBookmark(state: FacilityState, { payload }: PayloadAction<{ isActive: boolean, uid: string }>) {
      // @ts-ignore
      state.facilities.forEach(facility => {
        const foundWell = facility.wellsList?.find((well: any) => well.uid === payload.uid);
        if (foundWell) foundWell.isBookmarked = payload.isActive;
      });
    },
    clearFacilityInfo(state: FacilityState) {
      state.facilityInfo = null;
    },
    clearFacilitiesForm(state: FacilityState) {
      state.facilitiesForm = [];
    },
    changeWellRiskWellCard(state, { payload: incidentId }: PayloadAction<string>) {
      if (state.facilities[0]) {
        state.facilities[0].wellsList?.forEach(well => {
          if (well.incidentInfo) {
            const index = well.incidentInfo.findIndex(incident => incident.id === incidentId);
            if (index > -1) {
              well.incidentInfo.splice(index, 1);
            }
          }
        });
      }
    }
  },
  extraReducers: {
    [getFacilities.fulfilled.type]: (state, action: PayloadAction<FacilityType[]>) => {
      state.isLoading = false;
      state.facilities = action.payload;
      state.error = '';
    },
    [getFacilities.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getFacilities.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.facilities = [];
      state.error = action.payload;
    },
    [getFacilitiesForm.pending.type]: (state: FacilityState) => {
      state.isLoading = true;
    },
    [getFacilitiesForm.fulfilled.type]: (state: FacilityState, { payload }: PayloadAction<[]>) => {
      state.isLoading = false;
      state.facilitiesForm = facilityFormList2Client(payload);
    },
    [getFacilitiesForm.rejected.type]: (state: FacilityState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    },
    [getFacilityDetail.pending.type]: (state: FacilityState) => {
      state.isLoading = true;
    },
    [getFacilityDetail.fulfilled.type]: (state: FacilityState, { payload }: PayloadAction<FacilityType[]>) => {
      state.isLoading = false;
      state.facilities = facilityDetail2Client(payload);
    },
    [getFacilityDetail.rejected.type]: (state: FacilityState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.facilities = [];
      state.error = payload;
    },
    [getFacilityDetailMnemonics.pending.type]: (state: FacilityState) => {
      state.isLoading = true;
    },
    [getFacilityDetailMnemonics.fulfilled.type]: (state: FacilityState, { payload }: PayloadAction<FacilityType[]>) => {
      state.isLoading = false;
      state.facilitiesMnemonics = facilityDetail2Client(payload);
    },
    [getFacilityDetailMnemonics.rejected.type]: (state: FacilityState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.facilitiesMnemonics = [];
      state.error = payload;
    },
    [getFacility2StepSummary.pending.type]: (state: FacilityState) => {
      state.isLoading = true;
      state.facilityInfo = null;
    },
    [getFacility2StepSummary.fulfilled.type]: (state: FacilityState, { payload }: PayloadAction<FacilitySummaryResponseType>) => {
      state.isLoading = false;
      state.facilityInfo = facility2StepSummary2Client(payload);
    },
    [getFacility2StepSummary.rejected.type]: (state: FacilityState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.facilityInfo = null;
      state.error = payload;
    },
    [createOrUpdateProjectSecondStep.pending.type]: (state: FacilityState) => {
      state.isLoading = true;
    },
    [createOrUpdateProjectSecondStep.fulfilled.type]: (state: FacilityState, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      // state.facilityInfo = facilityDetail2Client(payload);
    },
    [createOrUpdateProjectSecondStep.rejected.type]: (state: FacilityState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.facilityInfo = null;
      state.error = payload;
    },
    [createFacilityLinkedToFieldAndArea.pending.type]: (state: FacilityState) => {
      state.isLoading = true;
    },
    [createFacilityLinkedToFieldAndArea.fulfilled.type]: (state: FacilityState, { payload }: PayloadAction<{ facilityList: [], newFacility: FacilityFormType }>) => {
      state.isLoading = false;
      state.facilitiesForm = facilityFormList2Client(payload.facilityList);
    },
    [createFacilityLinkedToFieldAndArea.rejected.type]: (state: FacilityState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export default facilitySlice.reducer;

import {
  CurveDataPointType, CurveDataType, MnemonicsLatestResponse, MnemonicsResponse
} from './types';
import { mnemonicsMapper } from './constants';

// перевод оригинальных названий мнемоник в наши
// todo это должно быть на беке
export const convertWitsmlNames = (witsmlName: string): string => mnemonicsMapper[witsmlName];

//
export const mnemonics2Client = (rawObject: MnemonicsResponse[]): CurveDataType[] => {
  return rawObject[0].dataset.map((elem) => {
    return { ...elem, name: convertWitsmlNames(elem.name) };
  });
};

// добавление новых точек в wellLogData
// todo - добавляем просто в конец, считаем, что данные всегда свежее, если нет - надо сортировать
export const updateWellLogData = (data: CurveDataType[], newPoints: CurveDataType[]) => {
  const concatArrays = (name: string, oldPoints: CurveDataPointType[]) => {
    const getNewPoints = () => {
      const curve = newPoints.find((newCurve) => newCurve.name === name);
      return curve && curve.data;
    };

    return oldPoints.concat(getNewPoints() || []).sort((a, b) => a[2] - b[2]);
  };

  return data.map((curve) => ({ ...curve, data: concatArrays(curve.name, curve.data) }));
};

// перевод последних значений в объект
export const latestMnemonics2Client = (rawObject: MnemonicsLatestResponse): object => {
  // return rawObject.dataset;
  return rawObject?.dataset?.reduce((acc, curr) => {
    // return {...acc, [convertWitsmlNames(curr.name)]: Number(curr.data).toFixed(2)};
    // использовался, когда приходили не правильные названия
    return { ...acc, [curr.name]: Number(curr.data[0][0]).toFixed(2) };
  }, {}) || {};
};

export const convertMnemonicsDataStrings = (data: CurveDataType[]): any => {
  const res = data.map((curve: CurveDataType) => {
    const points = curve.data.map((point) => point.map((el) => Number(el)));
    return { ...curve, data: [...points] };
  });
  return [...res];
};

// todo remove after tests
export const convertDataStrings = (data: any) => {
  const res = data[0].dataset.map((curve: any) => {
    const points = curve.data.map((point: string[]) => point.map((el: any) => Number(el)));
    return { ...curve, data: points };
  });
  return [{ ...data, dataset: res }];
};

export const newSampleData = [{
  meta: null,
  dataset: [{
    name: 'HKLD',
    data: [[206.708, 822.1, 1624886034175], [208.761, 822.3, 1624886063174], [206.977, 822.5, 1624886071170], [207.594, 822.7, 1624886084198], [208.41, 822.9, 1624886094170], [207.535, 823.1, 1624886102167], [206.956, 823.3, 1624886113163], [206.872, 823.5, 1624886126163], [206.442, 823.7, 1624886135181], [208.108, 823.9, 1624886142159], [206.806, 824.1, 1624886157156], [206.335, 824.3, 1624886183153], [205.909, 824.5, 1624886189192], [206.238, 824.7, 1624886196150], [206.784, 824.9, 1624886201149], [206.867, 825.1, 1624886206152], [208.309, 825.3, 1624886212148], [209.331, 825.5, 1624886218167], [209.973, 825.7, 1624886224144]]
  }, {
    name: 'RPM',
    data: [[14.23, 822.9, 1624886094170], [20.7, 823.1, 1624886102167], [25.89, 823.3, 1624886113163], [31.39, 823.5, 1624886126163], [33.41, 823.7, 1624886135181], [29.29, 823.9, 1624886148160], [21.06, 824.1, 1624886157156], [11.63, 824.3, 1624886183153], [8.24, 824.5, 1624886189192], [6.23, 824.7, 1624886196150], [4.47, 824.9, 1624886201149], [3.53, 825.1, 1624886206152], [3.13, 825.3, 1624886212148], [3, 825.5, 1624886218167], [2.9, 825.7, 1624886224144]]
  }
  ]
}
];

import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  createFasteningCalc,
  deleteFasConstruction,
  deleteFasEquipment,
  deleteFasFluid,
  getEquipmentCentralizer,
  getFasCentralization,
  getFasConstructions,
  getFasEquipments,
  getFasFluids,
  getFasteningCalc,
  getResearchJobData,
  updateEquipmentCentralizer,
  updateFasCentralization,
  updateFasteningConstruction,
  updateFasteningEquipment,
  updateFasteningFluid,
  updateJobData,
} from './actions';
import {
  CentralizationCalc,
  Centralizer,
  ICementingCalc,
  IFasCentralization,
  IFasConstructionEquipment,
  IFasEquipment,
  IFasSolution,
  IJobData
} from './types';
import options from './options';
import { calculateCementing } from 'models/hydraulics/actions';

const { name } = options;

interface IFasteningStore {
  error?: string;
  isLoading: boolean;
  cementing: ICementingCalc;
  fluids: IFasSolution[];
  jobData: IJobData[];
  equipments: IFasEquipment[];
  constructions: IFasConstructionEquipment[];
  centralization: CentralizationCalc[];
  centralizationEquipment: Centralizer[]
}

const initialState: IFasteningStore = {
  error: undefined,
  isLoading: false,
  cementing: {
    centralization: [],
    ecds: [],
    mds: [],
    hydrostatic_pressures: [],
    pressures: [],
    times: [],
    volumes: [],
    well_uuid: '',
  },
  centralization: [],
  centralizationEquipment: [],
  constructions: [],
  equipments: [],
  fluids: [],
  jobData: []
};

export const fasteningSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: {
    // research cementing

    [createFasteningCalc.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [createFasteningCalc.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<ICementingCalc>
    ) => {
      state.cementing = action.payload;
      //TODO action.payload.result или action.payload
      state.cementing = action.payload;
      state.isLoading = false;
    },
    [createFasteningCalc.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // get research cementing

    [getFasteningCalc.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getFasteningCalc.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<ICementingCalc>
    ) => {
      state.cementing = action.payload;
      state.isLoading = false;
    },
    [getFasteningCalc.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },


    // get calculate cementing
    [calculateCementing.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [calculateCementing.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<ICementingCalc>
    ) => {
      //@ts-ignore
      state.cementing = action.payload.result;
      state.isLoading = false;
    },
    [calculateCementing.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // get construction equipments

    [getFasConstructions.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getFasConstructions.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IFasConstructionEquipment[]>
    ) => {
      state.constructions = action.payload.sort((a, b) => a.index! - b.index!);
      state.isLoading = false;
    },
    [getFasConstructions.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // get equipments

    [getFasEquipments.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getFasEquipments.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IFasEquipment[]>
    ) => {
      state.equipments = action.payload.sort((a, b) => a.bha_index! - b.bha_index!);
      state.isLoading = false;
    },
    [getFasEquipments.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // get fluids

    [getFasFluids.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getFasFluids.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IFasSolution[]>
    ) => {
      state.fluids = action.payload;
      state.isLoading = false;
    },
    [getFasFluids.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    [updateJobData.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [updateJobData.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IJobData>
    ) => {
      let newFluids = [...state.jobData];
      newFluids = newFluids.map((jobData) => {
        if (jobData.fluid === action.payload.fluid) {
          return action.payload
        } else {
          return jobData
        }
      })
      state.jobData = newFluids;
      state.isLoading = false;
    },
    [updateJobData.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // update fluids
    [updateFasteningFluid.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IFasSolution>
    ) => {
      if (state.fluids) {
        let newFluids: IFasSolution[] = [...state.fluids].map((fluid) => {
          if (fluid.job_data_uuid === action.payload.job_data_uuid) {
            return action.payload
          } else {
            return fluid
          }

        })
        state.fluids = newFluids;
      }

      state.isLoading = false;
    },

    // update construction
    [updateFasteningConstruction.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IFasConstructionEquipment>
    ) => {
      if (state.constructions) {
        let newConstruction: IFasConstructionEquipment[] = [...state.constructions].map((construction) => {
          if (construction.item_uuid === action.payload.item_uuid) {
            return action.payload
          } else {
            return construction
          }

        })
        state.constructions = newConstruction;
      }

      state.isLoading = false;
    },

    // update Equipment
    [updateFasteningEquipment.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IFasEquipment>
    ) => {
      if (state.equipments) {
        let newEquipment: IFasEquipment[] = [...state.equipments].map((equip) => {
          if (equip.item_uuid === action.payload.item_uuid) {
            return action.payload
          } else {
            return equip
          }

        })
        state.equipments = newEquipment;
      }
      state.isLoading = false;
    },

    // get researchJobData
    [getResearchJobData.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getResearchJobData.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<IJobData[]>
    ) => {
      state.jobData = action.payload;
      state.isLoading = false;
    },
    [getResearchJobData.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },
    // get centralization

    [getFasCentralization.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getFasCentralization.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<CentralizationCalc[]>
    ) => {
      state.centralization = action.payload.sort((a, b) => a.index! - b.index!);
      state.isLoading = false;
    },
    [getFasCentralization.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    [getEquipmentCentralizer.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [getEquipmentCentralizer.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<Centralizer[]>
    ) => {
      state.centralizationEquipment = Array.isArray(action.payload) ? action.payload : []
      state.isLoading = false;
    },
    [getEquipmentCentralizer.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    [updateEquipmentCentralizer.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [updateEquipmentCentralizer.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<Centralizer>
    ) => {
      let newState = [...state.centralizationEquipment];
      newState = newState.map((equipmentItem) => {
        if (equipmentItem.item_uuid === action.payload.item_uuid) return action.payload
        return equipmentItem
      })
      state.centralizationEquipment = newState;
      state.isLoading = false;
    },
    [updateEquipmentCentralizer.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },

    // update centralization
    [updateFasCentralization.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [updateFasCentralization.fulfilled.type]: (
      state: IFasteningStore,
      action: PayloadAction<CentralizationCalc>
    ) => {
      state.centralization = [...state.centralization].map((item) => {
        if (item.item_uuid === action.payload.item_uuid) {
          return action.payload
        } else {
          return item
        }
      })
      state.isLoading = false;
    },
    [updateFasCentralization.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
    },
    // delete construction

    [deleteFasConstruction.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [deleteFasConstruction.fulfilled.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      const index = state.constructions.findIndex((eq) => eq.item_uuid === payload);
      if (index > -1) {
        state.constructions.splice(index, 1);
      }
      state.isLoading = false;
    },
    [deleteFasConstruction.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
      state.isLoading = false;
    },

    // delete equipment

    [deleteFasEquipment.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [deleteFasEquipment.fulfilled.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      const index = state.equipments.findIndex((eq) => eq.item_uuid === payload);
      if (index > -1) {
        state.equipments.splice(index, 1);
      }
      state.isLoading = false;
    },
    [deleteFasEquipment.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
      state.isLoading = false;
    },

    // delete fluid

    [deleteFasFluid.pending.type]: (state: IFasteningStore) => {
      state.isLoading = true;
    },
    [deleteFasFluid.fulfilled.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      const index = state.fluids.findIndex((eq) => eq.item_uuid === payload);
      if (index > -1) {
        state.fluids.splice(index, 1);
      }
      state.isLoading = false;
    },
    [deleteFasFluid.rejected.type]: (
      state: IFasteningStore,
      { payload }: PayloadAction<string>
    ) => {
      state.error = payload;
      state.isLoading = false;
    }
  }
});

export default fasteningSlice.reducer;

import options from './options';
import { getUrl } from '../utils';
import request from '../../utils/http/request';
import {
  ActualDateType,
  CreateDrillingOperationType,
  CreateOperationType, EditOperationType, EffectiveDateType, OperationTypes, ReorderOperationType
} from './types';

const { apiUrl } = options;

const url = getUrl(apiUrl);
const urlFact = getUrl(apiUrl + '/fact');

export const getDrillingStageApi = async () => request.get(url('drilling_stage'));

export const getDrillingOperationTypeApi = async (id: number): Promise<OperationTypes[]> => request.get(url(`drilling_operation_type/findByStageId/${id}`));

export const getDrillingOperationApi = async (wellBoreId: string) => request.get(url(`drilling_operation/findByWellboreId/${wellBoreId}`));
export const getDrillingOperationApiFact = async (wellBoreId: string) => request.get(url(`drilling_operation/fact/findByWellboreId/${wellBoreId}`));
export const getDrillingOperationAll = async (wellBoreId: string) => Promise.all(
  [getDrillingOperationApiFact(wellBoreId),
    getDrillingOperationApi(wellBoreId)
  ]
)
  .then((values) => values);
  
export const createDrillingOperationApi = async (body: CreateOperationType) => request.post(url('drilling_operation'), body);
export const createDrillingOperationApiFact = async (body: CreateOperationType) => request.post(url('drilling_operation/fact'), body);

export const getProjectDateApi = async (wellBoreId: string): Promise<EffectiveDateType> => request.get(url(`well_project/getEffectiveDateById/${wellBoreId}`));
export const getDrillingStartDateApi = async (uid: string): Promise<EffectiveDateType> => request.get(url(`well_project/getActualStartGraphicDateById/${uid}`));

export const updateProjectDateApi = async (body: ActualDateType): Promise<ActualDateType> => request.post(url('well_project/updateEffectiveDateById'), body);
export const updateProjectFactDateApi = async (body: ActualDateType): Promise<ActualDateType> => request.post(url('well_project/updateActualStartGraphicDateById'), body);

export const deleteOperationApi = async (id: number): Promise<void> => request.delete(url(`drilling_operation/${id}`));
export const deleteOperationApiFact = async (id: number): Promise<void> => request.delete(url(`drilling_operation/fact/${id}`));

export const deleteSomeOperationApi = async (ids: number[]): Promise<void> => request.delete(url('drilling_operation/deleteByListId'), ids);
export const deleteSomeOperationApiFact = async (ids: number[]): Promise<void> => request.delete(url('drilling_operation/fact/deleteByListId'), ids);

export const editOperationApi = async (body: EditOperationType): Promise<unknown> => request.put(url('drilling_operation/updateById'), body);
export const editOperationApiFact = async (body: EditOperationType): Promise<unknown> => request.put(url('drilling_operation/fact/updateById'), body);

export const reorderOperationApi = async (body: ReorderOperationType): Promise<unknown> => request.put(url('drilling_operation/updateOrderById'), body);
export const reorderOperationApiFact = async (body: ReorderOperationType): Promise<unknown> => request.put(url('drilling_operation/fact/updateOrderById'), body);

export const createDrillingTypeOperationApi = async (body: CreateDrillingOperationType): Promise<OperationTypes> => request.post(url('drilling_operation_type'), body);
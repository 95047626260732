import { createAsyncThunk } from '@reduxjs/toolkit';
import * as TrajectoryApi from './api';
import {
  anticollisionCalculation,
  checkIsWellCreated,
  createTrajectoryCalc,
  createTrajectoryWell,
  deleteJProfile,
  deleteNudgeProfile, deleteOptimumAlign,
  deleteSProfile,
  getFpAnalysisApi,
  getJProfileCalcByIdApi,
  getNudgeCalcByIdApi,
  getOptimumAlignCalcByIdApi,
  getProfileCalc,
  getSProfileCalcByIdApi,
  getSurveyTool,
  getTrajectoryCalc,
  linkOffsetWells
} from './api';
import options from './options';
import { ITrajectoryBase, ITrajectoryTableData, ProfileTypes } from './types';
import { IWellCommon } from '../well/types';
import {
  jProfileCalcToBack, nudgeCalcToBack, optimumAlignCalcToBack, sProfileCalcToBack
} from './converters';
import { JProfileFormType } from '../../pages/trajectory/tabs/Forecast/components/ForecastForm/JProfile/helper';
import { NudgeCalcFormType } from '../../pages/trajectory/tabs/Forecast/components/ForecastTable/helper';
import { SProfileCalcFormType } from '../../pages/trajectory/tabs/Forecast/components/ForecastForm/SProfile/helper';
import { OptimumAlignFormType } from '../../pages/trajectory/tabs/Forecast/components/ForecastForm/ArcStraightArc/helper';

const { name } = options;

export const getTrajectoryTracksByWellIds = createAsyncThunk(
  `${name}/getTrajectoryTracksByWellIds`,
  async (wellboreUids: string[], thunkApi) => {
    try {
      return await TrajectoryApi.getTrajectoryTracksByWellIdsApi(wellboreUids);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getTrajectoryTracksFactByWellIds = createAsyncThunk(
  `${name}/getTrajectoryTracksByWellIds`,
  async (wellboreUids: string[], thunkApi) => {
    try {
      return await TrajectoryApi.getTrajectoryTracksFactByWellIdsApi(wellboreUids);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getFactAndPlanTrajectory = createAsyncThunk(
  `${name}/getTrajectoryTracksByWellIds`,
  async (wellboreUids: string[], thunkApi) => {
    try {
      return await Promise.all([
        await TrajectoryApi.getTrajectoryTracksFactByWellIdsApi(wellboreUids),
        await TrajectoryApi.getTrajectoryTracksByWellIdsApi(wellboreUids)
      ]).then((values) => values.flat()).catch(() => []);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getTrajectoryTableDataByWellbore = createAsyncThunk(
  `${name}/getTrajectoryTableDataByWellId`,
  // todo 1000 - временно, надо чтобы бек научился принимать 0
  async ({
    isPlan,
    wellbore,
    page = 0,
    size = 1000
  }: { isPlan: boolean, wellbore: string, page?: number, size?: number }, thunkApi) => {
    try {
      return isPlan
        ? await TrajectoryApi.getTrajectoryTableDataByWellboreApi(wellbore, page, size)
        : await TrajectoryApi.getTrajectoryFactTableDataByWellboreApi(wellbore, page, size);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createOrUpdateTrajectoryTableItems = createAsyncThunk(
  `${name}/createOrUpdateTrajectoryTableItems`,
  async ({
    isPlan,
    wellboreUid,
    pointsData
  }: { isPlan: boolean, wellboreUid: string, pointsData: ITrajectoryBase[] }, thunkApi) => {
    try {
      return isPlan ? await TrajectoryApi.createOrUpdateTrajectoryPlanApi(pointsData, wellboreUid) : await TrajectoryApi.createOrUpdateTrajectoryFactApi(pointsData, wellboreUid);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const updateTrajectoryTableItem = createAsyncThunk(
  `${name}/updateTrajectoryTableItem`,
  async ({
    uid,
    data
  }: { uid: string, data: Partial<ITrajectoryBase> }, thunkApi) => {
    try {
      return await TrajectoryApi.updateTrajectoryTableItemApi(uid, data);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const checkTrajectoryPoints = createAsyncThunk(
  `${name}/checkTrajectoryPoints`,
  async ({
    wellboreUid
  }: { wellboreUid: string }, thunkApi) => {
    try {
      return await TrajectoryApi.checkTrajectoryPointsApi(wellboreUid);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const checkTrajectoryPlanPoints = createAsyncThunk(
  `${name}/checkTrajectoryPoints`,
  async ({
    wellboreUid
  }: { wellboreUid: string }, thunkApi) => {
    try {
      return await TrajectoryApi.checkTrajectoryFactPointsApi(wellboreUid);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteTrajectoryTableItem = createAsyncThunk(
  `${name}/deleteTrajectoryTableItem`,
  async ({
    isPlan,
    itemUid
  }: { isPlan: boolean, itemUid: string }, thunkApi) => {
    try {
      return isPlan ? await TrajectoryApi.deletePlanTrajectoryTableItemApi(itemUid) : await TrajectoryApi.deleteFactTrajectoryTableItemApi(itemUid);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteAllTrajectoryPlan = createAsyncThunk(
  `${name}/deleteAllTrajectoryPlan`,
  async (wellboreUid: string, thunkApi) => {
    try {
      return await TrajectoryApi.deleteAllTrajectoryBywellboreUid(wellboreUid);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteAllTrajectoryFact = createAsyncThunk(
  `${name}/deleteAllTrajectoryPlan`,
  async (wellboreUid: string, thunkApi) => {
    try {
      return await TrajectoryApi.deleteAllTrajectoryFactBywellboreUid(wellboreUid);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

// Intersection

type IntersectionCalcParamsType = {
  targetWell: IWellCommon;
  offsetWells: IWellCommon[];
};

export const intersectionCalculation = createAsyncThunk(
  `${name}/intersectionCalculation`,
  async (params: IntersectionCalcParamsType, thunkAPI) => {
    try {
      const offsetWellIds = params.offsetWells.map(well => well.uid);
      const targetWellId = params.targetWell.uid;
      const tools = await getSurveyTool();

      // Если запрос дает 404, то в "tool" должно стоят "Poor magnetization". Task/29272
      // const tool = tools[0];
      const tool = tools.find((toolItem) => toolItem.name === 'Poor magnetization');

      if (!tool) {
        throw new Error('Нету данных по приборам');
      }

      let targetWell = await checkIsWellCreated(targetWellId);
      if (!targetWell) {
        targetWell = await createTrajectoryWell(targetWellId, tool.itemUuid);
      }

      const promiseWells = offsetWellIds.map(async (wellId) => {
        let well = await checkIsWellCreated(wellId);
        if (!well) {
          well = await createTrajectoryWell(wellId, tool.itemUuid);
        }
        return well;
      });
      await Promise.all(promiseWells);

      let trajectoryCalc = await getTrajectoryCalc(targetWell.itemUuid);
      if (!trajectoryCalc) {
        trajectoryCalc = await createTrajectoryCalc(targetWell.itemUuid);
      }

      // const anticollisionCalc = await getAnticollisionCalc(trajectoryCalc.anticollisionCalcUuid);
      await linkOffsetWells(
        offsetWellIds, trajectoryCalc.itemUuid, trajectoryCalc.anticollisionCalcUuid
      );

      const answer = await anticollisionCalculation(trajectoryCalc.itemUuid);
      // const targetWellName = (thunkAPI.getState() as RootStateType).models.
      return {
        answer,
        targetWell: params.targetWell,
        offsetWells: params.offsetWells
      };
    } catch (e: any) {
      if (e.message.includes('offset_wells_trajs')) {
        return thunkAPI.rejectWithValue('В оффсетных скважинах нет траектории');
      }
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

// Forecast

export const getForecastInitialValues = createAsyncThunk(
  `${name}/getForecastInitialValues`,
  async (wellId: string, thunkAPI) => {
    try {
      const tools = await getSurveyTool();
      const tool = tools[0];
      let well = await checkIsWellCreated(wellId);
      if (!well) {
        well = await createTrajectoryWell(wellId, tool.itemUuid);
      }
      let trajectoryCalc = await getTrajectoryCalc(well.itemUuid);
      if (!trajectoryCalc) {
        trajectoryCalc = await createTrajectoryCalc(well.itemUuid);
      }
      return await getProfileCalc(trajectoryCalc.profileCalcUuid);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

type CreateNudgeCalcParams = {
  profileCalcId: string;
  body: NudgeCalcFormType;
};

export const createNudgeCalc = createAsyncThunk(
  `${name}/createNudgeCalc`,
  async (params: CreateNudgeCalcParams, thunkApi) => {
    try {
      return await TrajectoryApi.createNudgeCalcApi(nudgeCalcToBack(params.body, params.profileCalcId));
    } catch (e: any) {
      const message = e.response.data?.detail || e.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

type EditNudgeCalcParams = {
  profileCalcId: string;
  body: NudgeCalcFormType;
  nudgeId: string;
};

export const editNudgeCalc = createAsyncThunk(
  `${name}/editNudgeCalc`,
  async (params: EditNudgeCalcParams, thunkApi) => {
    try {
      return await TrajectoryApi.editNudgeCalcApi(nudgeCalcToBack(params.body, params.profileCalcId), params.nudgeId);
    } catch (e: any) {
      const message = e.response.data?.detail || e.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

type CreateJProfileCalcParams = {
  profileCalcId: string;
  body: JProfileFormType
};

export const createJProfileCalc = createAsyncThunk(
  `${name}/createJProfileCalc`,
  async (params: CreateJProfileCalcParams, thunkApi) => {
    try {
      return await TrajectoryApi.createJProfileCalcApi(jProfileCalcToBack(params.body, params.profileCalcId));
    } catch (e: any) {
      const message = e.response.data?.detail || e.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

type EditJProfileCalcParams = {
  profileCalcId: string;
  body: JProfileFormType
  jProfileId: string;
};

export const editJProfileCalc = createAsyncThunk(
  `${name}/editJProfileCalc`,
  async (params: EditJProfileCalcParams, thunkApi) => {
    try {
      return await TrajectoryApi.editJProfileCalcApi(
        jProfileCalcToBack(params.body, params.profileCalcId),
        params.jProfileId
      );
    } catch (e: any) {
      const message = e.response.data?.detail || e.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

type CreateSProfileCalcParams = {
  profileCalcId: string;
  body: SProfileCalcFormType
};

export const getProfileCalcById = createAsyncThunk(
  `${name}/getProfileCalcById`,
  async (params: any, thunkApi) => {
    try {
      let profile = null;
      switch (params.profileType) {
        case ProfileTypes.J:
          profile = await getJProfileCalcByIdApi(params.calcId);
          break;
        case ProfileTypes.S:
          profile = await getSProfileCalcByIdApi(params.calcId);
          break;
        case ProfileTypes.NudgeDlsInclAzim:
        case ProfileTypes.NudgeDlsTvdAzim:
        case ProfileTypes.NudgeMdInclAzim:
        case ProfileTypes.NudgeTvdInclAzim:
          profile = await getNudgeCalcByIdApi(params.calcId);
          break;
        case ProfileTypes.OptimumAlignTvd:
        case ProfileTypes.OptimumAlignTan:
        case ProfileTypes.OptimumAlignDls:
          profile = await getOptimumAlignCalcByIdApi(params.calcId);
          break;
        default:
          break;
      }
      return {
        ...profile,
        profileType: params.profileType
      };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createSProfileCalc = createAsyncThunk(
  `${name}/createSProfileCalc`,
  async (params: CreateSProfileCalcParams, thunkApi) => {
    try {
      return await TrajectoryApi.createSProfileCalcApi(sProfileCalcToBack(params.body, params.profileCalcId));
    } catch (e: any) {
      const message = e.response.data?.detail || e.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

type EditSProfileCalcParams = {
  profileCalcId: string;
  body: SProfileCalcFormType;
  sProfileId: string;
};

export const editSProfileCalc = createAsyncThunk(
  `${name}/createSProfileCalc`,
  async (params: EditSProfileCalcParams, thunkApi) => {
    try {
      return await TrajectoryApi.editSProfileCalcApi(
        sProfileCalcToBack(params.body, params.profileCalcId),
        params.sProfileId
      );
    } catch (e: any) {
      const message = e.response.data?.detail || e.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

type DeleteTrajectoryCalcParams = {
  calcId: string;
  profileType: ProfileTypes;
};

export const deleteTrajectoryCalc = createAsyncThunk(
  `${name}/deleteSurvey`,
  async (params: DeleteTrajectoryCalcParams, thunkApi) => {
    try {
      switch (params.profileType) {
        case ProfileTypes.J:
          await deleteJProfile(params.calcId);
          return params;
        case ProfileTypes.S:
          await deleteSProfile(params.calcId);
          return params;
        case ProfileTypes.NudgeDlsInclAzim:
        case ProfileTypes.NudgeDlsTvdAzim:
        case ProfileTypes.NudgeMdInclAzim:
        case ProfileTypes.NudgeTvdInclAzim:
        case ProfileTypes.NudgeUndefined:
          await deleteNudgeProfile(params.calcId);
          return params;
        case ProfileTypes.ForCreate:
        case ProfileTypes.Init:
          break;
        case ProfileTypes.OptimumAlignTan:
        case ProfileTypes.OptimumAlignDls:
        case ProfileTypes.OptimumAlignTvd:
          await deleteOptimumAlign(params.calcId);
          return params;
        default:
          break;
      }
      return null;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

type CreateOptimumAlignCalcParams = {
  body: OptimumAlignFormType;
  profileCalcId: string;
};

export const createOptimumAlignCalc = createAsyncThunk(
  `${name}/createOptimumAlignCalc`,
  async (params: CreateOptimumAlignCalcParams, thunkApi) => {
    try {
      return await TrajectoryApi.createOptimumAlignCalcApi(optimumAlignCalcToBack(params.body, params.profileCalcId));
    } catch (e: any) {
      const message = e.response.data?.detail || e.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

type EditOptimumAlignCalcParams = {
  body: OptimumAlignFormType;
  profileCalcId: string;
  optimumAlignId: string;
};

export const editOptimumAlignCalc = createAsyncThunk(
  `${name}/editOptimumAlignCalc`,
  async (params: EditOptimumAlignCalcParams, thunkApi) => {
    try {
      return await TrajectoryApi.editOptimumAlignCalcApi(
        optimumAlignCalcToBack(params.body, params.profileCalcId),
        params.optimumAlignId
      );
    } catch (e: any) {
      const message = e.response.data?.detail || e.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const downloadAnticollisionReport = createAsyncThunk(
  `${name}/downloadAnticollisionReport`,
  async (id: string, thunkAPI) => {
    try {
      const report = await TrajectoryApi.downloadAnticollisionReportApi({
        well_uuid: id
      });
      return {
        report,
        well_uuid: id
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const downloadForecastReport = createAsyncThunk(
  `${name}/downloadForecastReport`,
  async (id: string, thunkAPI) => {
    try {
      const report = await TrajectoryApi.downloadForecastReportApi({
        well_uuid: id
      });
      return {
        report,
        well_uuid: id
      };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getFpAnalysis = createAsyncThunk(
  `${name}/getFpAnalysis`,
  async (wellUid: string, thunkAPI) => {
    try {
      const tools = await getSurveyTool();
      const tool = tools[0];
      if (!tool) {
        throw new Error('Нету данных по приборам');
      }
      const well = await checkIsWellCreated(wellUid);
      if (!well) {
        await createTrajectoryWell(wellUid, tool.itemUuid);
      }
      const res = await getFpAnalysisApi(wellUid);
      return res[0];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
import rfdc from 'rfdc';
import {
  EquipmentTypeType,
  TubularAssemblyType,
  DrillingMudNoAssembly,
  EquipmentAssemblyType,
  EquipmentListElemType,
  UpdateAssemblyFormType,
  IDrillingMudResponseType,
  CreateTubularAssemblyBodyType, EquipmentType, BhaEquipmentType, EquipmentFrontType
} from './types';
import { ITrunkData } from '../../sdk/bha-editor/helper';

export const sortEquipmentsByIncrementalDepth = (list: TubularAssemblyType[]): TubularAssemblyType[] => list.sort((a, b) => a.name.localeCompare(b.name))
  .map(assembly => ({
    ...assembly,
    equipments: assembly.equipments
      .sort((a, b) => a.incrementalLength - b.incrementalLength)
  }));

// добавление типов и названий деталий в массив с бека
export const addTypeFields2client = (eqElem: EquipmentListElemType, typesList: EquipmentTypeType[]) => {
  if (typesList.length === 0) {
    return eqElem;
  }

  const eqs = eqElem.elements.map(equipment => {
    const clone = { ...equipment };
    const type = typesList.find(eqType => eqType.id === clone.type.id);

    // TODO: change typeCode to equipmentTypeCode, when back is ready
    return { ...clone, typeCode: type?.abbr || '' };
  });

  return { ...eqElem, elements: eqs };
};

// удаление лишних полей при апдейте сборки
export const updateAssemblyBody2server = (body: UpdateAssemblyFormType): CreateTubularAssemblyBodyType => {
  const { transformData, equipments } = rfdc()(body);

  const cuttedTransformData = transformData?.map((elem: ITrunkData) => {
    delete elem.rawDetail;
    return elem;
  }) || null;

  const cuttedEquipments = equipments?.map((eq: EquipmentAssemblyType) => {
    delete eq.typeCode;
    // @ts-ignore
    delete eq.equipmentTypeName;
    delete eq.id;
    return eq;
  }) || [];

  return {
    ...body,
    transformData: JSON.stringify(cuttedTransformData),
    equipments: cuttedEquipments.sort((a, b) => a.incrementalLength - b.incrementalLength)
  };
};

export const addAssemblyName = (assemblyList: TubularAssemblyType[], drillingMud: DrillingMudNoAssembly): IDrillingMudResponseType => {
  const assembly = assemblyList.find(assem => assem.id === drillingMud.tubularAssemblyId);
  return {
    ...drillingMud,
    tubularAssemblyName: assembly?.name || ''
  };
};

export const convertDownholeEquipment = (equipment: EquipmentType): Record<string, any> => {
  const res: Record<string, any> = {
    name: equipment.name,
    id: equipment.id,
    typeId: equipment.type.id,
    typeAbbr: equipment.type.abbr,
    typeName: equipment.type.name,
    GOST_MATERIAL: equipment.material ? equipment.material.id : '',
    STEEL_GRADE: equipment.grade ? equipment.grade.id : '',
  };

  equipment.equipmentSpecs.forEach(eq => {
    const { spec } = eq.id;
    res[spec.name] = eq.value;
  });
  return res;
};

export const convertEquipmentToBha = (equipment: EquipmentType): BhaEquipmentType => {
  const bhaEquipmentValues: Omit<BhaEquipmentType, 'equipmentName' | 'equipmentId' | 'equipmentType' | 'activeInd' | 'equipmentGroup' | 'equipmentTypeName'> = {
    interlockConnection: '',
    length: 0,
    weight: 0,
    outerDiameter: 0,
    internalDiameter: 0,
    strengthGroup: ''
  };

  equipment.equipmentSpecs.forEach(eq => {
    switch (eq.id.spec.name) {
      case 'LENGTH':
        bhaEquipmentValues.length = parseFloat(eq.value) || 0;
        break;
      case 'OUTER_DIAMETER':
        bhaEquipmentValues.outerDiameter = parseFloat(eq.value) || 0;
        break;
      case 'WEIGHT':
        bhaEquipmentValues.weight = parseFloat(eq.value) || 0;
        break;
      case 'INTERLOCK_CONNECTION':
        bhaEquipmentValues.interlockConnection = eq.value;
        break;
      case 'INTERNAL_DIAMETER':
        bhaEquipmentValues.internalDiameter = parseFloat(eq.value) || 0;
        break;
      case 'STRENGTH_GROUP':
        bhaEquipmentValues.strengthGroup = eq.value;
        break;
      default:
        break;
    }
  });

  return {
    equipmentId: equipment.id,
    equipmentName: equipment.name,
    equipmentType: equipment.type.id,
    activeInd: equipment.activityIndicator,
    equipmentGroup: equipment.type.group.id,
    equipmentTypeName: equipment.type.name,
    ...bhaEquipmentValues
  };
};

export const convertEqToFront = (equipment: EquipmentType): EquipmentFrontType => ({
  name: equipment.name,
  activityIndicator: equipment.activityIndicator,
  type: equipment.type,
  id: equipment.id,
  specs: convertDownholeEquipment(equipment)
});
import { PayloadAction } from '@reduxjs/toolkit';
import {
  BreadcrumbStateType, BreadcrumbType, IBreadcrumbElement, IFacilityBreadcrumb, IWellBreadcrumb
} from './types';
import { getWellNameTemporary } from '../../utils/utils';

export const mainPage: IBreadcrumbElement = {
  label: 'Главная',
  to: '/'
};

export const monitoringBreadcrumb: IBreadcrumbElement = {
  label: 'Фонд скважин',
  to: '/monitoring',
  disabled: false
};

export const projectDocumentationBreadcrumb: IBreadcrumbElement = {
  label: 'Проектная документация',
  to: '/projdoc',
  disabled: false
};

export const factDocumentationBreadcrumb: IBreadcrumbElement = {
  label: 'Фактическая документация',
  to: '/fact-doc',
  disabled: false
};

export const calculationBreadcrumb: IBreadcrumbElement = {
  label: 'Инженерные расчеты',
  to: '/calculations',
  disabled: true
};

export const trajectoryCalculationBreadcrumb: IBreadcrumbElement = {
  label: 'Генератор траекторий',
  to: '/calculations/trajectory',
  disabled: false
};

export const intersectionCalculationBreadcrumb: IBreadcrumbElement = {
  label: 'Прогноз пересечений',
  to: '/calculations/intersection',
  disabled: true
};

export const forecastCalculcationBreadcrumb: IBreadcrumbElement = {
  label: 'Построение',
  to: '/calculations/trajectory?tab=intersection',
  disabled: false
};

export const trajectoryAnalysisBreadcrumb: IBreadcrumbElement = {
  label: 'Анализ траектории',
  to: '/calculations/analysis',
  disabled: false
};

export const hydraulicsBreadcrumb: IBreadcrumbElement = {
  label: 'Гидравлика',
  to: '/hydraulics',
  disabled: false
};

export const tensionBreadcrumb: IBreadcrumbElement = {
  label: 'Натяжения и моменты',
  to: '/tensions',
  disabled: false
};

export const schedulerBreadcrumb: IBreadcrumbElement = {
  label: 'Ковер бурения',
  to: '/scheduler',
  disabled: false
};

export const directoryEquipmentBreadcrumb: IBreadcrumbElement = {
  label: 'Забойное оборудование',
  to: '/directory-equipment',
  disabled: false
};

export const addToLabel = (bread: IBreadcrumbElement) => {
  if (!bread?.label) {
    return;
  }

  switch (bread?.type) {
    case BreadcrumbType.facility:
      if (!bread.label.toLocaleLowerCase().includes('куст')) {
        bread.label = `Куст ${bread.label}`;
      }
      break;
    case BreadcrumbType.field:
      if (!bread.label.toLocaleLowerCase().includes('месторождение')) {
        bread.label += ' месторождение';
      }
      break;
    case BreadcrumbType.well:
      if (!bread.label.toLocaleLowerCase().includes('скважина')) {
        bread.label = `Скважина ${getWellNameTemporary(bread.label)}`;
      }
      break;
    case BreadcrumbType.area:
      if (!bread.label.toLocaleLowerCase().includes('площадь')) {
        bread.label = `Площадь ${bread.label}`;
      }
      break;
    default:
      break;
  }
};

export enum WellBreadType {
    Monitoring = 'monitoring',
    Projdoc = 'projdoc',
    Factdoc = 'fact-doc',
    Hydraulics = 'hydraulics',
    Trajectory = 'trajectory',
    TrajectoryAnalysis = 'analysis',
    Tension = 'tension',
}

export const buildWellBreadcrumb = (wellData: IWellBreadcrumb, fromArea: boolean, type: WellBreadType): IBreadcrumbElement[] => {
  switch (type) {
    case WellBreadType.Monitoring: {
      let fieldOrArea: IBreadcrumbElement = {
        label: wellData.fieldName,
        to: `/monitoring/field/${wellData.fieldId}`,
        type: BreadcrumbType.field,
        disabled: false
      };

      if (fromArea) {
        fieldOrArea = {
          label: wellData.areaName,
          to: `/monitoring/area/${wellData.areaType}&&${wellData.areaId}`,
          type: BreadcrumbType.area,
          disabled: false
        };
      }

      return [
        monitoringBreadcrumb,
        fieldOrArea,
        {
          label: wellData.facilityName,
          to: `/monitoring/facility/${wellData.facilityType}&&${wellData.facilityId}`,
          type: BreadcrumbType.facility,
          disabled: false
        },
        {
          label: wellData.wellName || '',
          type: BreadcrumbType.well,
          to: `/monitoring/well/${wellData.wellId}`
        }
      ];
    }

    case WellBreadType.Projdoc: {
      return [
        projectDocumentationBreadcrumb,
        {
          label: wellData.fieldName,
          to: `/projdoc?id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.field
        },
        {
          label: wellData.facilityName,
          to: `/projdoc?id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.facility
        },
        {
          label: wellData.wellName || '',
          type: BreadcrumbType.well,
          to: `/projdoc/well/${wellData.wellId}`
        }
      ];
    }
    case WellBreadType.Factdoc: {
      return [
        factDocumentationBreadcrumb,
        {
          label: wellData.fieldName,
          to: `/fact-doc?id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.field
        },
        {
          label: wellData.facilityName,
          to: `/fact-doc?id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.facility
        },
        {
          label: wellData.wellName || '',
          type: BreadcrumbType.well,
          to: `/fact-doc/well/${wellData.wellId}`
        }
      ];
    }

    case WellBreadType.Hydraulics: {
      return [
        hydraulicsBreadcrumb,
        {
          label: wellData.fieldName,
          to: `/hydraulics?id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.field
        },
        {
          label: wellData.facilityName,
          to: `/hydraulics?id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.facility
        },
        {
          label: wellData.wellName || '',
          type: BreadcrumbType.well,
          to: `/hydraulics/well/${wellData.wellId}`
        }
      ];
    }

    case WellBreadType.Trajectory: {
      return [
        forecastCalculcationBreadcrumb,
        {
          label: wellData.fieldName,
          to: `/calculations/trajectory/?tab=intersection&id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.field
        },
        {
          label: wellData.facilityName,
          to: `/calculations/trajectory/?tab=intersection&id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.facility
        },
        {
          label: wellData.wellName || '',
          type: BreadcrumbType.well,
          to: `/calculations/trajectory/forecast/${wellData.wellId}`
        }
      ];
    }

    case WellBreadType.TrajectoryAnalysis: {
      return [
        trajectoryAnalysisBreadcrumb,
        {
          label: wellData.fieldId,
          to: `/calculations/analysis/?id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.field,
          disabled: false
        },
        {
          label: wellData.facilityName,
          to: `/calculations/analysis/?id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.facility,
          disabled: false
        },
        {
          label: wellData.wellName || '',
          type: BreadcrumbType.well,
          to: `/calculations/analysis/${wellData.wellId}`,
          disabled: false
        }
      ];
    }

    case WellBreadType.Tension: {
      return [
        tensionBreadcrumb,
        {
          label: wellData.fieldId,
          to: `/tensions/?id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.field,
          disabled: false
        },
        {
          label: wellData.facilityName,
          to: `/tensions/?id=${wellData.facilityId}&type=${wellData.facilityType}`,
          type: BreadcrumbType.facility,
          disabled: false
        },
        {
          label: wellData.wellName || '',
          type: BreadcrumbType.well,
          to: `/tensions/${wellData.wellId}`,
          disabled: false
        }
      ];
    }

    default: return [];
  }
};

export const buildFacilityBreadcrumb = (facilityData: IFacilityBreadcrumb, fromArea: boolean): IBreadcrumbElement[] => {
  let fieldOrArea: IBreadcrumbElement = {
    label: facilityData.fieldName,
    to: `/monitoring/field/${facilityData.fieldId}`,
    type: BreadcrumbType.field,
    disabled: false
  };

  if (fromArea) {
    fieldOrArea = {
      label: facilityData.areaName,
      to: `/monitoring/area/${facilityData.areaType}&&${facilityData.areaId}`,
      type: BreadcrumbType.area,
      disabled: false
    };
  }

  return [
    monitoringBreadcrumb,
    fieldOrArea,
    {
      label: facilityData.facilityName,
      to: `/monitoring/facility/${facilityData.facilityType}&&${facilityData.facilityId}`,
      type: BreadcrumbType.facility
    }
  ];
};

export const reducerActions = {
  addBread(state: BreadcrumbStateType, { payload }: PayloadAction<IBreadcrumbElement>) {
    state.breadcrumbs[state.breadcrumbs.length - 1].disabled = false;
    payload.disabled = true;
    addToLabel(payload);
    state.breadcrumbs.push(payload);
  },
  removeSome(state: BreadcrumbStateType, { payload }: PayloadAction<number>) {
    // Удаляет n - кол-во последних элементов
    if (payload >= state.breadcrumbs.length) {
      state.breadcrumbs = [mainPage];
      return;
    }
    state.breadcrumbs.splice(state.breadcrumbs.length - payload, payload);
    state.breadcrumbs[state.breadcrumbs.length - 1].disabled = true;
  },
  removeLast(state: BreadcrumbStateType) {
    if (state.breadcrumbs.length > 1) {
      state.breadcrumbs.splice(state.breadcrumbs.length - 1, 1);
      state.breadcrumbs[state.breadcrumbs.length - 1].disabled = true;
    }
  },
  changeLast(state: BreadcrumbStateType, { payload }: PayloadAction<IBreadcrumbElement>) {
    if (state.breadcrumbs.length > 1) {
      payload.disabled = true;
      addToLabel(payload);
      state.breadcrumbs[state.breadcrumbs.length - 1] = payload;
    }
  },
  addSome(state: BreadcrumbStateType, { payload }: PayloadAction<IBreadcrumbElement[]>) {
    state.breadcrumbs[state.breadcrumbs.length - 1].disabled = false;
    state.breadcrumbs = state.breadcrumbs.concat(payload);
    state.breadcrumbs[state.breadcrumbs.length - 1].disabled = true;
  },
  sideMenuChange(state: BreadcrumbStateType, { payload }: PayloadAction<IBreadcrumbElement>) {
    payload.disabled = true;
    state.breadcrumbs = [mainPage, payload];
  },
  startFromMain(state: BreadcrumbStateType, { payload }: PayloadAction<IBreadcrumbElement[]>) {
    payload.forEach(bread => addToLabel(bread));
    const breads = [mainPage].concat(payload);
    breads[breads.length - 1].disabled = true;
    state.breadcrumbs = breads;
  },
  breadcrumbClick(state: BreadcrumbStateType, { payload }: PayloadAction<IBreadcrumbElement>) {
    const index = state.breadcrumbs.findIndex(bread => payload.label === bread.label && payload.to === bread.to);
    state.breadcrumbs = state.breadcrumbs.slice(0, index + 1);
    state.breadcrumbs[state.breadcrumbs.length - 1].disabled = true;
  },
  fromArea(state: BreadcrumbStateType, action: PayloadAction<boolean>) {
    state.fromArea = action.payload;
  },
  originalWellBread(state: BreadcrumbStateType, action: PayloadAction<IWellBreadcrumb>) {
    state.originalWellBread = action.payload;
  }
};

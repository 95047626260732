import { getUrl } from '../utils';
import {
  CreateLinkedFacilityBodyType,
  CreateProjectSecondStepBodyType,
  FacilityType,
  GetFacilityDetailParams,
  GetFacilitySummaryBodyType
} from './types';
import options from './options';
import request from '../../utils/http/request';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getFacilitiesApi = async (uid?: string | number): Promise<FacilityType[]> =>
  (!uid ? request.get(url('')) : request.get(url(uid)))
// return [facilityData];
;

export const getFacilitiesFormApi = async (): Promise<[]> => request.get(url(''));

export const getFacilityDetailApi = async (params: GetFacilityDetailParams): Promise<FacilityType[]> => {
  const getDetailParams = () => {
    const array = params.uid.split('&&');
    return {
      facilityId: array[1],
      facilityType: array[0]
    };
  };
  return request.post(url('findFacilityData'), {
    ids: [getDetailParams()],
    wellStatusType: params.wellStatusType
  });
};

export const getFacilityDetailMnemonicsApi = async (params: GetFacilityDetailParams): Promise<FacilityType[]> => {
  const getDetailParams = () => {
    const array = params.uid.split('&&');
    return {
      facilityId: array[1],
      facilityType: array[0]
    };
  };
  return request.post(url('findFacilityDataMnemonics'), {
    ids: [getDetailParams()],
    wellStatusType: params.wellStatusType
  });
};

export const createOrUpdateProjectSecondStepApi = (body: CreateProjectSecondStepBodyType): Promise<any> => request.post(url('project_documentation/update_facility_link_equipment'), body);

export const createFacilityLinkedToFieldAndAreaApi = (body: CreateLinkedFacilityBodyType): Promise<any> => request.post(url('add_facility_to_existing_field_and_area'), body);

export const getFacilitySummaryApi = async (body: GetFacilitySummaryBodyType): Promise<any> => request.post(url('summary'), body);

const facilityData = {
  uid: 331,
  facilityName: 'some_bush331',
  wellCount: 13,
  wellActive: 10,
  ropAverage: 113,
  wellRisk: 2,
  wellRiskDetail: [
    {
      wellUid: 'id_ скважины42',
      wellName: 'номер скважины42'
    },
    {
      wellUid: 'id_ скважины43',
      wellName: 'номер скважины43'
    }
  ],
  wellsList: [
    {
      uid: 22,
      wellName: '42',
      wellStatus: 'Приостановлено',
      wellDepth: 24556,
      rop: 143,
      wellRisk: true,
      wellDepthPlan: 33456,
      isBookmarked: false
    },
    {
      uid: 32,
      wellName: '179Г',
      wellStatus: 'Бурение',
      wellDepth: 12,
      rop: 113,
      wellRisk: false,
      wellDepthPlan: 25400,
      isBookmarked: false
    },
    {
      uid: 62,
      wellName: '12376Д',
      wellStatus: 'Подъем',
      wellDepth: 24456,
      rop: 143,
      wellRisk: true,
      wellDepthPlan: 24666,
      isBookmarked: false
    },
    {
      uid: 72,
      wellName: '3082',
      wellStatus: 'Завершено',
      wellDepth: 28556,
      rop: 113,
      wellRisk: false,
      wellDepthPlan: 28556,
      isBookmarked: false
    },
    {
      uid: 42,
      wellName: '42',
      wellStatus: 'Приостановлено',
      wellDepth: 24556,
      rop: 143,
      wellRisk: true,
      wellDepthPlan: 33456,
      isBookmarked: false
    },
    {
      uid: 52,
      wellName: '4702',
      wellStatus: 'Спуск',
      wellDepth: 4556,
      rop: 113,
      wellRisk: false,
      wellDepthPlan: 23556,
      isBookmarked: false
    }
  ]
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { getLastMnemonic, getMnemonicNames, getMnemonicsDataLatest, getMnemonicsForPeriod } from './actions';
import {
  CurveDataType, LatestMnemonicDataType, MnemonicNames,
  MnemonicsLatestResponse,
  MnemonicsResponse,
  MnemonicUpdatePayload
} from './types';

import { latestMnemonics2Client, updateWellLogData } from './converters';
import { getCurveList, WellMultiselectItemType } from '../../pages/monitoring/pages/well/forms/well-tablet/utils';

const { name } = options;

type WellLogState = {
    isLoading: boolean
    wellLogData: CurveDataType[],
    lastData: Record<MnemonicNames, string> | {},
    latestData: LatestMnemonicDataType[] | null,
    error: string,
    latestDateTime: number | null;
    curveList: WellMultiselectItemType[]
};

const initialState: WellLogState = {
  isLoading: false,
  wellLogData: [],
  lastData: {},
  latestData: [],
  error: '',
  latestDateTime: null,
  curveList: getCurveList()
};

export const wellLogSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearWellLogData(state) {
      state.wellLogData = [];
    },
    updateLatestMnemonicData(state, { payload }: PayloadAction<{ currentId: string, message: MnemonicsLatestResponse }>) {
      // TODO: check
      // const { currentId, message } = payload;
      // // проверка на соответствие ID скважины в сообщении
      // if (message.dataset.length && currentId === message.wellId) {
      //     state.latestData = latestMnemonics2Client(message);
      // }
    },
    // добавление новых точек, полученных по ws
    updateMnemonicData(state, { payload }: PayloadAction<MnemonicUpdatePayload>) {
      const { currentId, message } = payload;
      // проверка на соответствие ID скважины в сообщении
      if (message.dataset.length && currentId === message.wellId) {
        state.wellLogData = state.wellLogData.length
          ? updateWellLogData(state.wellLogData, message.dataset)
          : message.dataset;
      }
    }
  },
  extraReducers: {
    [getMnemonicsForPeriod.pending.type]: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    [getMnemonicsForPeriod.fulfilled.type]: (state, action: PayloadAction<MnemonicsResponse>) => {
      state.isLoading = false;
      state.wellLogData = action.payload.dataset;
      // state.wellLogData = convertDataStrings(mnemonics2Client(action.payload)); // mock data
      state.error = '';
    },
    [getMnemonicsForPeriod.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.wellLogData = [];
      state.error = action.payload;
    },
    [getLastMnemonic.pending.type]: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    [getLastMnemonic.fulfilled.type]: (state, action: PayloadAction<MnemonicsLatestResponse>) => {
      state.isLoading = false;
      state.lastData = latestMnemonics2Client(action.payload);
      if (action.payload.dataset) {
        state.latestDateTime = action.payload.dataset[0]?.data[0][2];
      }
      state.error = '';
    },
    [getLastMnemonic.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.lastData = {};
      state.error = action.payload;
    },

    [getMnemonicsDataLatest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    [getMnemonicsDataLatest.fulfilled.type]: (state, action: PayloadAction<MnemonicsLatestResponse>) => {
      state.isLoading = false;
      state.latestData = action.payload.dataset;
      state.error = '';
    },
    [getMnemonicsDataLatest.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.latestData = null;
      state.error = action.payload;
    },

    [getMnemonicNames.pending.type]: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    [getMnemonicNames.fulfilled.type]: (state, action: PayloadAction<WellMultiselectItemType[]>) => {
      if (action.payload.length > 0) {
        state.curveList = action.payload.sort((a, b) => a.name.localeCompare(b.name));
      }
      state.error = '';
      state.isLoading = false;
    },
    [getMnemonicNames.rejected.type]: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});

export default wellLogSlice.reducer;

import { ICalculationOffset, ICalculationTarget } from './types';
import { TrajectoryPointType } from '../../sdk/trajectory/types';

export const trajTo3dModel = (trajWell: ICalculationOffset | ICalculationTarget): TrajectoryPointType => ({
  z: trajWell.tvd,
  x: trajWell.northing,
  y: trajWell.easting
});
export const trajTo3dModelOffset = (trajWell: ICalculationOffset): TrajectoryPointType => ({
  z: trajWell.z,
  x: trajWell.x,
  y: trajWell.y
});
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  INITIAL_CONSTRUCTION,
  localizeTrunk,
  localizeVTrunk, trunkPositionOrder,
  VTRUNK_INDEX
} from '../../pages/project-documentation/pages/well-doc/construction/helper';
import {
  createTrunkAction,
  createTrunkActionFact,
  deleteTrunkAction, deleteTrunkActionFact, editOpenAndCenterTrunk,
  editOpenAndCenterTrunkFact,
  editTrunkAction,
  editTrunkWithActive,
  editTrunkWithActiveFact,
  getDataToEditTrunk,
  getDataToEditTrunkFact,
  getTrunkById,
  getTrunks,
  getTrunksFact,
  getWellboreDirectory,
  getWellboreDirectoryFact,
  updateOriginalTrunk
} from './actions';
import { IWellDirectory, TrunkType } from './types';
import {
  IConstruction,
  ITrunk,
  IVTrunk,
  TrunkName
} from '../../pages/project-documentation/pages/well-doc/construction/types';

interface IState {
    isLoading: boolean,
    construction: IConstruction,
    trunks: ITrunk[],
    vTrunk: IVTrunk | null,
    trunksOriginal: TrunkType[], // todo отрефакторить стейт и убрать это поле
    error: string,
    dataForAddingTrunk: IWellDirectory[],
    trunksForAdding: TrunkName[],
    dataToEdit: IWellDirectory[],
    trunkData: TrunkType | null,
    editLoading: boolean,
    allPageLoading: boolean
}

const initialState: IState = {
  isLoading: false,
  construction: INITIAL_CONSTRUCTION,
  trunks: [], // this array must be sorted by position
  trunksOriginal: [],
  error: '',
  dataForAddingTrunk: [],
  trunksForAdding: [],
  dataToEdit: [],
  trunkData: null,
  vTrunk: null,
  editLoading: false,
  allPageLoading: false
};

export const constructionSlice = createSlice({
  name: 'construction',
  initialState,
  reducers: {
    changeConstruction: (state: IState, action: PayloadAction<IConstruction>) => {
      state.construction = action.payload;
    },
    clearTrunks(state) {
      state.trunks = [];
      state.trunksOriginal = [];
    }
  },
  extraReducers: {
    [getTrunks.pending.type]: (state: IState) => {
      state.allPageLoading = true;
    },
    [getTrunks.fulfilled.type]: (state: IState, action: PayloadAction<TrunkType[]>) => {
      const trunks: ITrunk[] = action.payload.filter(tr => tr.name !== TrunkName.OPEN).map(tr => localizeTrunk(tr));
      const vTrunk = action.payload.find(tr => tr.name === TrunkName.OPEN);
      state.trunks = trunks;
      if (vTrunk) {
        state.vTrunk = localizeVTrunk(vTrunk);
      }
      state.trunksOriginal = action.payload
        .filter(tr => tr.name !== TrunkName.OPEN)
        .sort((a, b) => trunkPositionOrder[b.name] - trunkPositionOrder[a.name]);
      state.construction.trunks = trunks;
      state.allPageLoading = false;
      // state.construction.trunks = trunks;
    },
    [getTrunks.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = action.payload;
    },

    [getTrunksFact.pending.type]: (state: IState) => {
      state.allPageLoading = true;
    },
    [getTrunksFact.fulfilled.type]: (state: IState, action: PayloadAction<TrunkType[]>) => {
      const trunks: ITrunk[] = action.payload.filter(tr => tr.name !== TrunkName.OPEN).map(tr => localizeTrunk(tr));
      const vTrunk = action.payload.find(tr => tr.name === TrunkName.OPEN);
      state.trunks = trunks;
      if (vTrunk) {
        state.vTrunk = localizeVTrunk(vTrunk);
      }
      state.trunksOriginal = action.payload
        .filter(tr => tr.name !== TrunkName.OPEN)
        .sort((a, b) => trunkPositionOrder[b.name] - trunkPositionOrder[a.name]);
      state.construction.trunks = trunks;
      state.allPageLoading = false;
      // state.construction.trunks = trunks;
    },
    [getTrunksFact.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.allPageLoading = false;
      state.error = action.payload;
    },

    [getTrunkById.pending.type]: (state: IState) => {
      state.isLoading = true;
      state.trunkData = null;
    },
    [getTrunkById.fulfilled.type]: (state: IState, { payload }: PayloadAction<TrunkType>) => {
      state.trunkData = payload;
      state.isLoading = false;
    },
    [getTrunkById.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getWellboreDirectory.pending.type]: (state: IState) => {

    },
    [getWellboreDirectory.fulfilled.type]: (state: IState, action: PayloadAction<IWellDirectory[]>) => {
      state.dataForAddingTrunk = action.payload;
      state.trunksForAdding = action.payload.map(dir => dir.name);
    },
    [getWellboreDirectory.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    [getWellboreDirectoryFact.pending.type]: (state: IState) => {

    },
    [getWellboreDirectoryFact.fulfilled.type]: (state: IState, action: PayloadAction<IWellDirectory[]>) => {
      state.dataForAddingTrunk = action.payload;
      state.trunksForAdding = action.payload.map(dir => dir.name);
    },
    [getWellboreDirectoryFact.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    [createTrunkAction.pending.type]: (state: IState) => {

    },
    [createTrunkAction.fulfilled.type]: (state: IState, action: PayloadAction<TrunkType>) => {
      if (action.payload.name === TrunkName.OPEN) {
        state.vTrunk = localizeVTrunk(action.payload);
        return state;
      }
      const copyTrunks = [...state.trunks];
      copyTrunks.push(localizeTrunk(action.payload));
      copyTrunks.sort((a, b) => a.position - b.position);
      state.trunks = copyTrunks;
    },
    [createTrunkAction.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    [createTrunkActionFact.pending.type]: (state: IState) => {

    },
    [createTrunkActionFact.fulfilled.type]: (state: IState, action: PayloadAction<TrunkType>) => {
      if (action.payload.name === TrunkName.OPEN) {
        state.vTrunk = localizeVTrunk(action.payload);
        return state;
      }
      const copyTrunks = [...state.trunks];
      copyTrunks.push(localizeTrunk(action.payload));
      copyTrunks.sort((a, b) => a.position - b.position);
      state.trunks = copyTrunks;
    },
    [createTrunkActionFact.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    [getDataToEditTrunk.pending.type]: (state: IState) => {
      state.allPageLoading = true;
    },
    [getDataToEditTrunk.fulfilled.type]: (state: IState, action: PayloadAction<IWellDirectory[]>) => {
      state.dataToEdit = action.payload;
      state.allPageLoading = false;
    },
    [getDataToEditTrunk.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.allPageLoading = false;
    },

    [getDataToEditTrunkFact.pending.type]: (state: IState) => {
      state.allPageLoading = true;
    },
    [getDataToEditTrunkFact.fulfilled.type]: (state: IState, action: PayloadAction<IWellDirectory[]>) => {
      state.dataToEdit = action.payload;
      state.allPageLoading = false;
    },
    [getDataToEditTrunkFact.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.allPageLoading = false;
    },

    [deleteTrunkAction.pending.type]: (state: IState) => {

    },
    [deleteTrunkAction.fulfilled.type]: (state: IState, action: PayloadAction<TrunkName>) => {
      if (action.payload === TrunkName.OPEN) {
        state.vTrunk = null;
        return state;
      }
      state.trunks = state.trunks.filter(tr => tr.name !== action.payload);
    },
    [deleteTrunkAction.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    [deleteTrunkActionFact.pending.type]: (state: IState) => {

    },
    [deleteTrunkActionFact.fulfilled.type]: (state: IState, action: PayloadAction<TrunkName>) => {
      if (action.payload === TrunkName.OPEN) {
        state.vTrunk = null;
        return state;
      }
      state.trunks = state.trunks.filter(tr => tr.name !== action.payload);
    },
    [deleteTrunkActionFact.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    [editTrunkAction.pending.type]: (state: IState) => {

    },
    [editTrunkAction.fulfilled.type]: (state: IState, action: PayloadAction<TrunkType>) => {
      const index = state.trunks.findIndex(tr => tr.name === action.payload.name);
      if (index > -1) {
        state.trunks[index] = localizeTrunk(action.payload);
      }
    },
    [editTrunkAction.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    [updateOriginalTrunk.pending.type]: (state: IState) => {
      state.editLoading = true;
    },
    [updateOriginalTrunk.fulfilled.type]: (state: IState, { payload }: PayloadAction<TrunkType>) => {
      state.editLoading = false;
      state.trunksOriginal = state.trunksOriginal.map(tr => {
        if (tr.globalId === payload.globalId) {
          return payload;
        }
        return tr;
      });
    },
    [updateOriginalTrunk.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.editLoading = false;
    },

    [editTrunkWithActive.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [editTrunkWithActive.fulfilled.type]: (
      state: IState,
      action: PayloadAction<{ trunk: TrunkType, nextActiveTrunk: number }>
    ) => {
      const { trunk, nextActiveTrunk } = action.payload;
      const index = state.trunks.findIndex(tr => tr.name === trunk.name);
      if (index > -1) {
        state.trunks[index] = localizeTrunk(trunk);
      }

      if (index !== nextActiveTrunk) {
        if (nextActiveTrunk === VTRUNK_INDEX && state.vTrunk) {
          state.vTrunk.active = true;
        } else {
          state.trunks[nextActiveTrunk].active = true;
        }
      }
    },
    [editTrunkWithActive.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    [editTrunkWithActiveFact.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [editTrunkWithActiveFact.fulfilled.type]: (
      state: IState,
      action: PayloadAction<{ trunk: TrunkType, nextActiveTrunk: number }>
    ) => {
      const { trunk, nextActiveTrunk } = action.payload;
      const index = state.trunks.findIndex(tr => tr.name === trunk.name);
      if (index > -1) {
        state.trunks[index] = localizeTrunk(trunk);
      }

      if (index !== nextActiveTrunk) {
        if (nextActiveTrunk === VTRUNK_INDEX && state.vTrunk) {
          state.vTrunk.active = true;
        } else {
          state.trunks[nextActiveTrunk].active = true;
        }
      }
    },
    [editTrunkWithActiveFact.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    [editOpenAndCenterTrunk.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [editOpenAndCenterTrunk.fulfilled.type]: (
      state: IState,
      action: PayloadAction<{ trunks: TrunkType[], nextActiveTrunk: number | null }>
    ) => {
      const { trunks, nextActiveTrunk } = action.payload;
      const openTrunkIndex = trunks.findIndex(tr => tr.name === TrunkName.OPEN);

      if (openTrunkIndex > -1) {
        state.vTrunk = localizeVTrunk(trunks[openTrunkIndex]);
      }
      const centerTrunk = openTrunkIndex === 0 ? trunks[1] : trunks[0];
      state.trunks[0] = localizeTrunk(centerTrunk);

      if (nextActiveTrunk !== null) {
        if (nextActiveTrunk === VTRUNK_INDEX && state.vTrunk) {
          state.vTrunk.active = true;
        } else {
          state.trunks[nextActiveTrunk].active = true;
        }
      }
    },
    [editOpenAndCenterTrunk.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    [editOpenAndCenterTrunkFact.pending.type]: (state: IState) => {
      state.isLoading = true;
    },
    [editOpenAndCenterTrunkFact.fulfilled.type]: (
      state: IState,
      action: PayloadAction<{ trunks: TrunkType[], nextActiveTrunk: number | null }>
    ) => {
      const { trunks, nextActiveTrunk } = action.payload;
      const openTrunkIndex = trunks.findIndex(tr => tr.name === TrunkName.OPEN);

      if (openTrunkIndex > -1) {
        state.vTrunk = localizeVTrunk(trunks[openTrunkIndex]);
      }
      const centerTrunk = openTrunkIndex === 0 ? trunks[1] : trunks[0];
      state.trunks[0] = localizeTrunk(centerTrunk);

      if (nextActiveTrunk !== null) {
        if (nextActiveTrunk === VTRUNK_INDEX && state.vTrunk) {
          state.vTrunk.active = true;
        } else {
          state.trunks[nextActiveTrunk].active = true;
        }
      }
    },
    [editOpenAndCenterTrunkFact.rejected.type]: (state: IState, action: PayloadAction<string>) => {
      state.error = action.payload;
    }
  }
});

export default constructionSlice.reducer;

import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import {
  ADMIN, EXPERT, GUEST, USER
} from './models/auth/constants';
import Layout from './containers/layout';
import Private from './containers/private';
import Auth from './pages/authorization/auth';
import NoMatch from './pages/no-match';
import { WithRouter } from './containers/with-router';

const Dashboard = React.lazy(() => import('./pages/home/dashboard'));
const Monitoring = React.lazy(() => import('./pages/monitoring/Monitoring'));
const ProjectDocHome = React.lazy(() => import('./pages/project-documentation'));
const FactDocHome = React.lazy(() => import('./pages/fact-documentation'));
const Users = React.lazy(() => import('./pages/users/users'));
const Registration = React.lazy(() => import('./pages/registration/registration'));
const Scheduler = React.lazy(() => import('./pages/scheduler/Scheduler'));
const FasteningPage = React.lazy(() => import('./pages/fastening'));
const HydraulicsPage = React.lazy(() => import('./pages/hydraulics'));
const Field = React.lazy(() => import('./pages/monitoring/pages/field/field'));
const Facility = React.lazy(() => import('./pages/monitoring/pages/facility/facility'));
const Well = React.lazy(() => import('./pages/monitoring/pages/well/well'));
const Area = React.lazy(() => import('./pages/monitoring/pages/area/area'));
const WellDoc = React.lazy(() => import('./pages/project-documentation/pages/well-doc/well-doc'));
const WellFactDoc = React.lazy(() => import('./pages/fact-documentation/pages/well-doc/well-doc'));
const HydWell = React.lazy(() => import('./pages/hydraulics/pages/well'));
const FasteningWell = React.lazy(() => import('./pages/fastening/pages/well'));
const Trajectory = React.lazy(() => import('./pages/trajectory'));
const Forecast = React.lazy(() => import('./pages/trajectory/tabs/Forecast'));
const AnalysisStartHeader = React.lazy(() => import('./pages/trajectory-analysis/components/AnalysisStartHeader'));
const TrajAnalysis = React.lazy(() => import('./pages/trajectory-analysis'));
const TensionsPage = React.lazy(() => import('./pages/tension'));
const TensionWell = React.lazy(() => import('./pages/tension/pages/well'));
const DirectoryEquipment = React.lazy(() => import('./pages/directoryEquipment'));
const ArtificialIntelligencePage = React.lazy(() => import('./pages/artificial-intelligence'));

// const { user } = useContext(AuthContext);

export const router = createBrowserRouter([
  {
    path: '/auth',
    element: <Auth />,
    id: 'auth'
  },
  {
    path: '/',
    element: (
      <Private redirectPath="/auth" roles={[ADMIN, EXPERT, GUEST, USER]}>
        <Layout />
      </Private>
    ),
    id: 'layout',
    // all children are protected because of Layout component
    children: [
      {
        path: '',
        element: (
          <WithRouter>
            <Dashboard />
          </WithRouter>
        ),
        id: 'home'

      },

      {
        path: 'monitoring/*',
        element: (
          <WithRouter>
            <Monitoring />
          </WithRouter>
        ),
        id: 'monitoring'
      },
      {
        path: 'monitoring/field/:id',
        element: (
          <WithRouter>
            <Field />
          </WithRouter>
        ),
        id: 'field'
      },
      {
        path: 'monitoring/facility/:id',
        element: (
          <WithRouter>
            <Facility />
          </WithRouter>
        ),
        id: 'facility'
      },
      {
        path: 'monitoring/well/:id',
        element: (
          <WithRouter>
            <Well />
          </WithRouter>
        ),
        id: 'well'
      },
      {
        path: 'monitoring/area/:id',
        element: (
          <WithRouter>
            <Area />
          </WithRouter>
        ),
        id: 'area'
      },

      {
        path: 'projdoc',
        element: (
          <WithRouter>
            <ProjectDocHome />
          </WithRouter>
        ),
        id: 'projdoc'
      },
      {
        path: 'projdoc/well/:id',
        element: (
          <WithRouter>
            <WellDoc />
          </WithRouter>
        ),
        id: 'wellDoc'
      },
      {
        path: 'fact-doc',
        element: (
          <WithRouter>
            <FactDocHome />
          </WithRouter>
        ),
        id: 'fact-doc'
      },
      {
        path: 'fact-doc/well/:id',
        element: (
          <WithRouter>
            <WellFactDoc />
          </WithRouter>
        ),
        id: 'wellFactDoc'
      },

      {
        path: 'artificial-intelligence',
        element: (
          <WithRouter>
            <ArtificialIntelligencePage />
          </WithRouter>
        ),
        id: 'artificial-intelligence'
      },

      {
        path: 'users',
        element: (
          <WithRouter>
            <Users />
          </WithRouter>
        ),
        id: 'users'
      },
      {
        path: 'registration',
        element: (
          <WithRouter>
            <Registration />
          </WithRouter>
        ),
        id: 'registration'
      },
      {
        path: 'calculations/analysis/:id',
        element: (
          <WithRouter>
            <TrajAnalysis />
          </WithRouter>
        ),
        id: 'analysis'
      },
      {
        path: 'calculations/analysis/',
        element: (
          <WithRouter>
            <AnalysisStartHeader />
          </WithRouter>
        ),
        id: 'start-analysis'
      },

      {
        path: 'calculations/trajectory',
        element: (
          <WithRouter>
            <Trajectory />
          </WithRouter>
        ),
        id: 'calculations'
      },
      {
        path: 'calculations/trajectory/forecast/:id',
        element: (
          <WithRouter>
            <Forecast />
          </WithRouter>
        ),
        id: 'forecast'
      },

      {
        path: 'scheduler',
        element: (
          <WithRouter>
            <Scheduler />
          </WithRouter>
        ),
        id: 'scheduler'
      },

      {
        path: 'fastening',
        element: (
          <WithRouter>
            <FasteningPage />
          </WithRouter>
        ),
        id: 'fastening'
      },
      {
        path: 'fastening/well/:id',
        element: (
          <WithRouter>
            <FasteningWell />
          </WithRouter>
        ),
        id: 'fasteningWell'
      },

      {
        path: 'hydraulics',
        element: (
          <WithRouter>
            <HydraulicsPage />
          </WithRouter>
        ),
        id: 'hydraulics'
      },
      {
        path: 'hydraulics/well/:id',
        element: (
          <WithRouter>
            <HydWell />
          </WithRouter>
        ),
        id: 'hydraulics/well'
      },

      {
        path: 'tensions',
        element: (
          <WithRouter>
            <TensionsPage />
          </WithRouter>
        ),
        id: 'tensions'
      },
      {
        path: 'tensions/well/:id',
        element: (
          <WithRouter>
            <TensionWell />
          </WithRouter>
        ),
        id: 'tensions/well'
      },

      {
        path: 'directory-equipment',
        element: (
          <WithRouter>
            <DirectoryEquipment />
          </WithRouter>
        ),
        id: 'directory-equipment'
      },

      {
        path: '*',
        element: <NoMatch />,
        id: 'no-match'
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" />
  }
]);
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as fieldApi from './api';
import options from './options';
import { CreateProjectFirstStepBodyType } from './types';
import { field2Client } from './converters';
import { WellStatus } from '../well/types';

const { name } = options;

export const getFieldsDetail = createAsyncThunk(
  `${name}/getFieldsDetail`,
  async (status: WellStatus[], thunkApi) => {
    try {
      return await fieldApi.getFieldsDetailApi(status);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
export const getFieldsDetailMnemonics = createAsyncThunk(
  `${name}/getFieldsDetailMnemonics`,
  async (status: WellStatus[], thunkApi) => {
    try {
      return await fieldApi.getFieldsDetailMnemonicsApi(status);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

type GetFieldFacilitiesDetailParams = {
    uid: string;
    status: WellStatus[] | string[];
};

export const getFieldFacilitiesDetail = createAsyncThunk(
  `${name}/getFieldFacilitiesDetail`,
  async (params: GetFieldFacilitiesDetailParams, thunkApi) => {
    try {
      return await fieldApi.getFieldFacilitiesDetailApi(params.uid, params.status);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getFieldsForm = createAsyncThunk(
  `${name}/getFieldsForm`,
  async (_, thunkApi) => {
    try {
      return await fieldApi.getFieldsApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getFieldByUid = createAsyncThunk(
  `${name}/getFieldByUid`,
  async (id: number | string, thunkApi) => {
    try {
      return await fieldApi.getFieldsApi(id);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createField = createAsyncThunk(
  `${name}/createField`,
  async (fieldName: string, thunkApi) => {
    try {
      await fieldApi.createFieldApi({
        fieldName,
        fieldType: 'нефтяная'
      });
      const fieldsList = await fieldApi.getFieldsApi();
      const newField = fieldsList.find((field: any) => field.fieldName === fieldName) || {};

      return { fieldsList, newField: field2Client(newField) };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

// первый шаг на форме добавления проекта
export const createOrUpdateProjectFirstStep = createAsyncThunk(
  `${name}/createOrUpdateProjectFirstStep`,
  async (body: CreateProjectFirstStepBodyType, thunkApi) => {
    try {
      return await fieldApi.createOrUpdateProjectFirstStepApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getFieldInfoByFieldId = createAsyncThunk(
  `${name}/getFieldInfoByFieldId`,
  async (fieldId: string, thunkApi) => {
    try {
      return await fieldApi.getFieldInfoByFieldIdApi(fieldId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const checkExistingFieldByName = createAsyncThunk(
  `${name}/checkExistingFieldByName`,
  async (fieldName: string, thunkApi) => {
    try {
      return await fieldApi.checkExistingFieldByNameApi(fieldName);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const postDraftFields = createAsyncThunk(
  `${name}/postDraftFields`,
  async (body: { value: string }, thunkApi) => {
    try {
      return await fieldApi.postDraftFieldsApi(body);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getDraftFields = createAsyncThunk(
  `${name}/getDraftFields`,
  async (_, thunkApi) => {
    try {
      return await fieldApi.getDraftFieldsApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteDraftFields = createAsyncThunk(
  `${name}/deleteDraftFields`,
  async (_, thunkApi) => {
    try {
      return await fieldApi.deleteDraftFieldsApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

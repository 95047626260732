import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {
  CoordinateSystemType,
  IBookmarkedField, IWellBoreGet, IWellDetailed, IWellForm, WellProjectType, WellStatus
} from './types';
import {
  addWellToFavorites,
  createOrUpdateProjectFourthStep,
  createOrUpdateProjectThirdStep,
  deleteWellFromFavorites,
  getBookmarkedWellsByCurrentUser, getCoordinateSystem,
  getUnassignedWells, getWellboreByWellId,
  getWellByUid,
  getWellProject,
  getWells,
  getWellsDetailedByFacilityId,
  getWellStatus,
  searchWellsByName,
  updateWellFacility,
  updateWellProjectById,
  updateWellStatus
} from './actions';

const { name } = options;

type WellState = {
  isLoading: boolean;
  wellsSearch: IWellForm[];
  wellsList: IWellDetailed[];
  error: string;
  wellsUnassigned: IWellForm[];
  well: IWellForm;
  bookmarkedWells: IBookmarkedField[];
  projectData: WellProjectType;
  // wellInfo: CreateProjectThirdStepFormType | null // поля формы на 3 шаге создания проекта.
  wellStatus: WellStatus | '';
  wellbore: null | IWellBoreGet;
  coordinateSystemList: CoordinateSystemType[];

  fakeLoading: boolean;
};

const initialState: WellState = {
  isLoading: false,
  wellsSearch: [],
  wellsList: [],
  error: '',
  wellsUnassigned: [],
  well: {
    uid: '',
    wellName: '',
    statusType: '',
    incidentInfo: null
  },
  bookmarkedWells: [],
  wellStatus: '',
  projectData: {
    uid: '',
    wellName: null,
    effectiveDate: undefined,
    drillingDuration: null,
    planSpeed: null,
    contractor: '',
    master: '',
    licenseSquare: '',
    licenseNumber: '',
    wellAlias: '',
    wellboreAlias: '',
    wellboreType: null,
    wellboreSlope: null,
    productLevel: null,
    formationPressure: null,
    toleranceRadius: null,
    latitude: null,
    longitude: null,
    tvdssmin: null,
    tvdssmax: null,
    tvdmin: null,
    tvdmax: null,
    offset: null,
    azimuth: null,
    drillingSet: null,
    wellDrillingSetType: null,
    wellDriverType: null,
    groundElev: 1.5,
    rotaryTableElev: 1.5,
    coordinateSystem: '',
    coordinateSystemType: 'LOCAL',
    xoffsetWell: null,
    tvd: null,
    bottomHoleLatitude: null,
    bottomHoleLongitude: null,
    xoffsetWellbore: null,
    yoffsetWell: null,
    yoffsetWellbore: null
  },
  fakeLoading: false,
  wellbore: null,
  coordinateSystemList: []
};

export const wellSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearWellsList(state) {
      state.wellsList = [];
    },
    clearWellsSearchList(state) {
      state.wellsSearch = [];
    },
    changeRiskWell(state, { payload: incidentId }: PayloadAction<string>) {
      if (state.well.incidentInfo) {
        const index = state.well.incidentInfo.findIndex(incident => incident.id === incidentId);
        if (index > -1) {
          state.well.incidentInfo.splice(index, 1);
        }
      }
    },
    clearWell(state) {
      state.well = {
        uid: '',
        wellName: '',
        statusType: '',
        incidentInfo: null
      };
    },
    activateFakeLoading(state) {
      state.fakeLoading = true;
    },
    cancelFakeLoading(state) {
      state.fakeLoading = false;
    }
  },
  extraReducers: {
    [getWellByUid.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.well = payload;
      state.error = '';
    },
    [getWellByUid.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getWellByUid.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getUnassignedWells.pending.type]: (state: WellState) => {
      // state.isLoading = true; // todo Пока убрал, чтобы не вылезал лоадер гдето на стронице
    },
    [getUnassignedWells.fulfilled.type]: (state: WellState, { payload }: PayloadAction<[]>) => {
      state.wellsUnassigned = payload;
      state.isLoading = false;
      state.error = '';
    },
    [getUnassignedWells.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.wellsUnassigned = [];
    },
    [updateWellFacility.pending.type]: (state: WellState) => {
      state.isLoading = true;
    },
    [updateWellFacility.fulfilled.type]: (state: WellState) => {
      state.isLoading = false;
      state.error = '';
    },
    [updateWellFacility.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.wellsUnassigned = [];
      state.error = action.payload;
    },
    [getWells.pending.type]: (state: WellState) => {
      state.isLoading = true;
      state.wellsSearch = [];
    },
    [getWells.fulfilled.type]: (state: WellState, { payload }: PayloadAction<any[]>) => {
      state.wellsSearch = payload;
      state.isLoading = false;
      state.error = '';
    },
    [getWells.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.wellsSearch = [];
    },
    [getWellsDetailedByFacilityId.pending.type]: (state: WellState) => {
      state.isLoading = true;
      state.wellsList = [];
    },
    [getWellsDetailedByFacilityId.fulfilled.type]: (state: WellState, { payload }: PayloadAction<IWellDetailed[]>) => {
      state.wellsList = payload;
      state.isLoading = false;
      state.error = '';
    },
    [getWellsDetailedByFacilityId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.wellsList = [];
    },

    // well bookmarked
    [addWellToFavorites.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.well.isBookmarked = true;
      state.error = '';
    },
    [addWellToFavorites.pending.type]: (state) => {
      state.isLoading = true;
    },
    [addWellToFavorites.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    },
    [deleteWellFromFavorites.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.well.isBookmarked = false;
      state.error = '';
    },
    [deleteWellFromFavorites.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteWellFromFavorites.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getBookmarkedWellsByCurrentUser.fulfilled.type]: (state, { payload }: PayloadAction<IBookmarkedField[]>) => {
      state.isLoading = false;
      state.bookmarkedWells = payload;
      state.error = '';
    },
    [getBookmarkedWellsByCurrentUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getBookmarkedWellsByCurrentUser.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [searchWellsByName.pending.type]: (state: WellState) => {
      state.isLoading = true;
      state.wellsSearch = [];
    },
    [searchWellsByName.fulfilled.type]: (state: WellState, { payload }: PayloadAction<IWellForm[]>) => {
      state.wellsSearch = payload;
      state.isLoading = false;
      state.error = '';
    },
    [searchWellsByName.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.wellsSearch = [];
    },
    [createOrUpdateProjectThirdStep.pending.type]: (state: WellState) => {
      state.isLoading = true;
    },
    [createOrUpdateProjectThirdStep.fulfilled.type]: (state: WellState) => {
      state.isLoading = false;
      state.error = '';
    },
    [createOrUpdateProjectThirdStep.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [createOrUpdateProjectFourthStep.pending.type]: (state: WellState) => {
      state.isLoading = true;
    },
    [createOrUpdateProjectFourthStep.fulfilled.type]: (state: WellState) => {
      state.isLoading = false;
      state.error = '';
    },
    [createOrUpdateProjectFourthStep.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getWellProject.pending.type]: (state: WellState) => {
      state.isLoading = true;
    },
    [getWellProject.fulfilled.type]: (state: WellState, action: PayloadAction<WellProjectType>) => {
      state.isLoading = false;
      state.error = '';
      state.projectData = action.payload;
    },
    [getWellProject.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [updateWellProjectById.pending.type]: (state: WellState) => {
      state.isLoading = true;
    },
    [updateWellProjectById.fulfilled.type]: (state: WellState, action: PayloadAction<WellProjectType>) => {
      state.isLoading = false;
      state.error = '';
      state.projectData = action.payload;
    },
    [updateWellProjectById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getWellStatus.pending.type]: (state: WellState) => {
      state.isLoading = true;
    },
    [getWellStatus.fulfilled.type]: (state: WellState, action: PayloadAction<WellStatus>) => {
      state.isLoading = false;
      state.error = '';
      state.wellStatus = action.payload || '';
    },
    [getWellStatus.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [updateWellStatus.pending.type]: (state: WellState) => {
      state.isLoading = true;
    },
    [updateWellStatus.fulfilled.type]: (state: WellState, action: PayloadAction<WellStatus>) => {
      state.isLoading = false;
      state.error = '';
      state.wellStatus = action.payload;
    },
    [updateWellStatus.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getWellboreByWellId.pending.type]: (state: WellState) => {
      state.isLoading = true;
    },
    [getWellboreByWellId.fulfilled.type]: (state: WellState, action: PayloadAction<IWellBoreGet | null>) => {
      state.isLoading = false;
      state.error = '';
      state.wellbore = action.payload;
    },
    [getWellboreByWellId.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getCoordinateSystem.pending.type]: (state: WellState) => {
      state.isLoading = true;
    },
    [getCoordinateSystem.fulfilled.type]: (state: WellState, action: PayloadAction<CoordinateSystemType[]>) => {
      state.isLoading = false;
      state.error = '';
      state.coordinateSystemList = action.payload;
    },
    [getCoordinateSystem.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default wellSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import {
  createTrunkApi,
  createTrunkApiFact,
  deleteTrunkApi,
  deleteTrunkApiFact,
  editOpenAndCenterTrunkApi,
  editOpenAndCenterTrunkApiFact,
  editTrunkApi,
  editTrunkApiFact,
  getDataToEditTrunkApi,
  getDataToEditTrunkFactApi,
  getTrunkByIdApi,
  getTrunkByIdApiFact,
  getTrunksApi,
  getTrunksFactApi,
  getWellboreDirectoryApi,
  getWellboreDirectoryApiFact
} from './api';
import { EditTrunkType, CreateTrunkType } from './types';
import { TrunkName } from '../../pages/project-documentation/pages/well-doc/construction/types';

const { name } = options;

export const getTrunks = createAsyncThunk(
  `${name}/trunks`,
  async (wellboreId: string, thunkApi) => {
    try {
      return await getTrunksApi(wellboreId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getTrunksFact = createAsyncThunk(
  `${name}/trunks`,
  async (wellboreId: string, thunkApi) => {
    try {
      return await getTrunksFactApi(wellboreId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getTrunkById = createAsyncThunk(
  `${name}/getTrunkById`,
  async (globalId: number, thunkApi) => {
    try {
      return await getTrunkByIdApi(globalId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
export const getTrunkByIdFact = createAsyncThunk(
  `${name}/getTrunkById`,
  async (globalId: number, thunkApi) => {
    try {
      return await getTrunkByIdApiFact(globalId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const getWellboreDirectory = createAsyncThunk(
  `${name}/directory`,
  async (wellboreId: string, thunkApi) => {
    try {
      return await getWellboreDirectoryApi(wellboreId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
export const getWellboreDirectoryFact = createAsyncThunk(
  `${name}/directory`,
  async (wellboreId: string, thunkApi) => {
    try {
      return await getWellboreDirectoryApiFact(wellboreId);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const createTrunkAction = createAsyncThunk(
  `${name}/createTrunk`,
  async (option: { wellboreId: string, trunk: CreateTrunkType }, thunkApi) => {
    try {
      return await createTrunkApi(option.wellboreId, option.trunk);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
export const createTrunkActionFact = createAsyncThunk(
  `${name}/createTrunk`,
  async (option: { wellboreId: string, trunk: CreateTrunkType }, thunkApi) => {
    try {
      return await createTrunkApiFact(option.wellboreId, option.trunk);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);


export const getDataToEditTrunk = createAsyncThunk(
  `${name}/dataToEdit`,
  async (_, thunkApi) => {
    try {
      return await getDataToEditTrunkApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
export const getDataToEditTrunkFact = createAsyncThunk(
  `${name}/dataToEdit`,
  async (_, thunkApi) => {
    try {
      return await getDataToEditTrunkFactApi();
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteTrunkAction = createAsyncThunk(
  `${name}/deleteTrunk`,
  async (option: { wellboreId: string, name: TrunkName }, thunkApi) => {
    try {
      return await deleteTrunkApi(option.wellboreId, option.name);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteTrunkActionFact = createAsyncThunk(
  `${name}/deleteTrunk`,
  async (option: { wellboreId: string, name: TrunkName }, thunkApi) => {
    try {
      return await deleteTrunkApiFact(option.wellboreId, option.name);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const editTrunkAction = createAsyncThunk(
  `${name}/editTrunk`,
  async (option: { wellboreId: string, trunk: EditTrunkType }, thunkApi) => {
    try {
      return await editTrunkApi(option.wellboreId, option.trunk);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
export const editTrunkActionFact = createAsyncThunk(
  `${name}/editTrunk`,
  async (option: { wellboreId: string, trunk: EditTrunkType }, thunkApi) => {
    try {
      return await editTrunkApiFact(option.wellboreId, option.trunk);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const updateOriginalTrunk = createAsyncThunk(
  `${name}/updateOriginalTrunk`,
  async ({ wellboreId, trunk }: { wellboreId: string, trunk: EditTrunkType }, thunkApi) => {
    try {
      return await editTrunkApi(wellboreId, trunk);
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const editTrunkWithActive = createAsyncThunk(
  `${name}/editWithActive`,
  async (option: { wellboreId: string, trunk: EditTrunkType, nextActiveTrunk: number | null }, thunkApi) => {
    try {
      const res = await editTrunkApi(option.wellboreId, option.trunk);
      return { trunk: res, nextActiveTrunk: option.nextActiveTrunk };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const editTrunkWithActiveFact = createAsyncThunk(
  `${name}/editWithActive`,
  async (option: { wellboreId: string, trunk: EditTrunkType, nextActiveTrunk: number | null }, thunkApi) => {
    try {
      const res = await editTrunkApiFact(option.wellboreId, option.trunk);
      return { trunk: res, nextActiveTrunk: option.nextActiveTrunk };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

type EditOpenAndCenterTrunkOptions = {
  wellboreId: string,
  trunks: EditTrunkType[],
  nextActiveTrunk: number | null
};

export const editOpenAndCenterTrunk = createAsyncThunk(
  `${name}/editOpenAndCenterTrunk`,
  async ({ wellboreId, trunks, nextActiveTrunk }: EditOpenAndCenterTrunkOptions, thunkApi) => {
    try {
      const response = await editOpenAndCenterTrunkApi(wellboreId, trunks);
      return { trunks: response, nextActiveTrunk };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
export const editOpenAndCenterTrunkFact = createAsyncThunk(
  `${name}/editOpenAndCenterTrunk`,
  async ({ wellboreId, trunks, nextActiveTrunk }: EditOpenAndCenterTrunkOptions, thunkApi) => {
    try {
      const response = await editOpenAndCenterTrunkApiFact(wellboreId, trunks);
      return { trunks: response, nextActiveTrunk };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

// export const editTrunksAction = createAsyncThunk(
//     `${name}/editTrunks`,
//     async (option: {wellId: string, trunks: EditTrunkType[]}, thunkApi) => {
//         try {
//             return await editTrunksApi(option.wellId, option.trunks);
//         } catch (e: any) {
//             return thunkApi.rejectWithValue(e.message);
//         }
//     }
// );

import { DrillingOperationType } from 'models/ggd/types';
import { ChartLine } from '../../sdk/lineChart/types';
import { formatDate } from '../../utils/date';
import { minToStringTime } from '../../utils/utils';
import { DrillingOperationStatusType } from './types';
import { generateLine as generateGGDLine } from 'models/ggd/convertor';

export const generateLine = (operations: DrillingOperationStatusType[], color: string = '#008850', byDate = true): ChartLine[] => {
  const line: ChartLine = {
    points: [],
    color: color,
    pointed: {
      is: true,
      showTooltip: true,
      tooltipData: {}
    }
  };
  operations.forEach(operation => {
    const dateMs = byDate ? new Date(operation.beginDateTimeInclusive).getTime() : +operation.sumDay;
    line.points.push(dateMs, operation.beginMd);
    if (line.pointed && line.pointed.tooltipData) {
      line.pointed.tooltipData[dateMs + operation.endMd] = [

        {
          key: 'Дата проведения работ',
          content: formatDate(operation.beginDateTimeInclusive, 'dd.MM.yyyy HH:mm')
        },
        {
          key: 'Продолжительность',
          content: minToStringTime(operation.beginDateTimeInclusive) || ''
        }
      ];
    }
  });
  return [line];
};

export const generateLineDouble = (operations: DrillingOperationType[], documentation: DrillingOperationStatusType[]): ChartLine[] => {
  const line: ChartLine = generateGGDLine(operations)
  const [secondLine] = generateLine(documentation, '#0051CA', false)

  return [line, secondLine];
};
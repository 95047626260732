import {
  CalcSurveyBackType,
  CalcSurveyType, FpAnalysisBackType, FpAnalysisResultBackType, FpAnalysisResultType, FpAnalysisType,
  IAnticollisionCalc,
  IAnticollisionCalcBack,
  IProfileCalc,
  IProfileCalcBack,
  ISurveyTool,
  ISurveyToolBack,
  ITrajectoryCalc,
  ITrajectoryCalcBack,
  ITrajectoryWell,
  ITrajectoryWellBack,
  JProfileCalcBackType,
  JProfileCalcCreateBackType,
  JProfileCalcType,
  NudgeCalcBackType,
  NudgeCalcCreateBackType,
  NudgeCalcType, OptimumAlignBackType, OptimumAlignCreateBackType, OptimumAlignType,
  ProfileTypes, ResultFactBackType, ResultFactType,
  SegmentBackType,
  SProfileCalcBackType,
  SProfileCalcCreateBackType,
  SProfileCalcType,
  TypedSurvey,
  WellBoreTrackType
} from './types';
import { JProfileFormType } from '../../pages/trajectory/tabs/Forecast/components/ForecastForm/JProfile/helper';
import { NudgeCalcFormType } from '../../pages/trajectory/tabs/Forecast/components/ForecastTable/helper';
import { SProfileCalcFormType } from '../../pages/trajectory/tabs/Forecast/components/ForecastForm/SProfile/helper';
import { OptimumAlignFormType } from '../../pages/trajectory/tabs/Forecast/components/ForecastForm/ArcStraightArc/helper';

// Intersection

export const toolToFront = (tool: ISurveyToolBack): ISurveyTool => ({
  name: tool.name,
  description: tool.description,
  itemData: tool.item_data,
  itemMeta: tool.item_meta,
  itemUuid: tool.item_uuid,
  rowChangedBy: tool.row_changed_by,
  rowChangedDatetime: tool.row_changed_datetime,
  rowCreatedBy: tool.row_created_by,
  rowCreatedDatetime: tool.row_created_datetime,
  errorModel: tool.error_model
});

export const trajectoryWellToFront = (well: ITrajectoryWellBack): ITrajectoryWell => ({
  itemData: well.item_data,
  itemMeta: well.item_meta,
  itemUuid: well.item_uuid,
  rowChangedBy: well.row_changed_by,
  rowChangedDatetime: well.row_changed_datetime,
  rowCreatedBy: well.row_created_by,
  rowCreatedDatetime: well.row_created_datetime,
  type: well.type,
  surveyToolUuids: well.survey_tool_uuids
});

export const trajectoryWellToBack = (well: ITrajectoryWell): ITrajectoryWellBack => ({
  type: well.type,
  survey_tool_uuids: well.surveyToolUuids,
  row_created_datetime: well.rowCreatedDatetime,
  row_created_by: well.rowCreatedBy,
  row_changed_datetime: well.rowChangedDatetime,
  row_changed_by: well.rowChangedBy,
  item_data: well.itemData,
  item_meta: well.itemMeta,
  item_uuid: well.itemUuid
});

export const trajectoryCalcToFront = (trajectoryCalc: ITrajectoryCalcBack): ITrajectoryCalc => ({
  itemData: trajectoryCalc.item_data,
  itemMeta: trajectoryCalc.item_meta,
  itemUuid: trajectoryCalc.item_uuid,
  rowChangedBy: trajectoryCalc.row_changed_by,
  rowChangedDatetime: trajectoryCalc.row_changed_datetime,
  rowCreatedBy: trajectoryCalc.row_created_by,
  rowCreatedDatetime: trajectoryCalc.row_created_datetime,
  anticollisionCalcUuid: trajectoryCalc.anticollision_calc_uuid,
  profileCalcUuid: trajectoryCalc.profile_calc_uuid,
  wellUuid: trajectoryCalc.well_uuid
});

export const calcSurveyToFront = (survey: CalcSurveyBackType): CalcSurveyType => ({
  azim: survey.azim,
  dls: survey.dls,
  buildRate: survey.build_rate,
  easting: survey.easting,
  incl: survey.incl,
  itemUuid: survey.item_uuid,
  md: survey.md,
  mdDelta: survey.md_delta,
  tvd: survey.tvd,
  northing: survey.northing,
  tfo: survey.tfo,
  totalAzim: survey.total_azim,
  totalDisp: survey.total_disp,
  turnRate: survey.turn_rate,
  tvdss: survey.tvdss
});

export const calcSurveyToBack = (survey: CalcSurveyType): CalcSurveyBackType => ({
  azim: survey.azim,
  dls: survey.dls,
  tvdss: survey.tvdss,
  tfo: survey.tfo,
  md: survey.md,
  incl: survey.incl,
  turn_rate: survey.turnRate,
  tvd: survey.tvd,
  total_disp: survey.totalDisp,
  northing: survey.northing,
  easting: survey.easting,
  total_azim: survey.totalAzim,
  md_delta: survey.mdDelta,
  item_uuid: survey.itemUuid,
  build_rate: survey.buildRate
});

export const calcSurveyToTypedSurvey = (survey: CalcSurveyBackType, profileType: ProfileTypes, calcId: string): TypedSurvey => ({
  ...calcSurveyToFront(survey),
  profileType,
  calcId
});

export const segmentsToSurvey = (segments: (SegmentBackType | CalcSurveyBackType)[]): TypedSurvey[] => {
  const surveys: TypedSurvey[] = [];
  segments.forEach(segment => {
    if ('surveys' in segment) {
      segment.surveys.forEach(survey => {
        surveys.push({
          ...calcSurveyToFront(survey),
          profileType: segment.profile_type,
          calcId: segment.profile_calc_uuid
        });
      });
    } else {
      surveys.push(calcSurveyToTypedSurvey(segment, ProfileTypes.Init, segment.item_uuid));
    }
  });
  return surveys;
};

export const profileCalcToFront = (profileCalc: IProfileCalcBack): IProfileCalc => ({
  itemData: profileCalc.item_data,
  itemMeta: profileCalc.item_meta,
  itemUuid: profileCalc.item_uuid,
  rowChangedBy: profileCalc.row_changed_by,
  rowChangedDatetime: profileCalc.row_changed_datetime,
  rowCreatedBy: profileCalc.row_created_by,
  rowCreatedDatetime: profileCalc.row_created_datetime,
  linkedWithTrajectoryCalc: profileCalc.linked_with_trajectory_calc,
  wellUuid: profileCalc.well_uuid,
  segments: [],
  mdStep: profileCalc.md_step
});

export const profileCalcToBack = (profileCalc: IProfileCalc): IProfileCalcBack => ({
  well_uuid: profileCalc.wellUuid,
  segments: [],
  md_step: profileCalc.mdStep,
  linked_with_trajectory_calc: profileCalc.linkedWithTrajectoryCalc,
  row_created_datetime: profileCalc.rowCreatedDatetime,
  row_created_by: profileCalc.rowCreatedBy,
  row_changed_datetime: profileCalc.rowChangedDatetime,
  row_changed_by: profileCalc.rowChangedBy,
  item_data: profileCalc.itemData,
  item_meta: profileCalc.itemMeta,
  item_uuid: profileCalc.itemUuid
});

export const nudgeCalcToFront = (body: NudgeCalcBackType): NudgeCalcType => ({
  surveys: body.surveys.map(survey => calcSurveyToFront(survey)),
  tvd: body.tvd,
  incl: body.incl,
  calcType: body.calc_type,
  dls: body.dls,
  mdLen: body.md_len,
  azim: body.azim,
  itemMeta: body.item_meta,
  itemUuid: body.item_uuid,
  itemData: body.item_data,
  linkedWithProfileCalc: body.linked_with_profile_calc,
  rowChangedDatetime: body.row_created_datetime,
  md: body.md,
  profileType: body.profile_type,
  rowCreatedBy: body.row_created_by,
  rowCreatedDatetime: body.row_created_datetime,
  rowChangedBy: body.row_changed_by,
  isAutoAzim: body.is_auto_azim,
  isAutoDls: body.is_auto_dls,
  isAutoIncl: body.is_auto_incl,
  isAutoMd: body.is_auto_md,
  isAutoMdLen: body.is_auto_md_len,
  isAutoTvd: body.is_auto_tvd
});
export const nudgeCalcToBack = (body: NudgeCalcFormType, profileCalcId: string): NudgeCalcCreateBackType => ({
  azim: body.azim,
  dls: body.isDlsChecked ? body.dls : null,
  is_md_len_checked: false,
  md_len: null,
  md: body.isMdChecked ? body.md : null,
  incl: body.incl,
  calc_type: body.calcType,
  profile_type: body.profileType,
  linked_with_profile_calc: profileCalcId,
  tvd: body.isTvdChecked ? body.tvd : null,
  item_meta: null,
  item_data: null
});

export const jProfileCalcToFront = (body: JProfileCalcBackType): JProfileCalcType => ({
  azim: body.azim,
  buildRate: body.build_rate,
  itemData: body.item_data,
  itemMeta: body.item_meta,
  itemUuid: body.item_uuid,
  linkedWithProfileCalc: body.linked_with_profile_calc,
  rowChangedBy: body.row_changed_by,
  rowChangedDatetime: body.row_changed_datetime,
  rowCreatedBy: body.row_created_by,
  rowCreatedDatetime: body.row_created_datetime,
  surveys: body.surveys.map(survey => calcSurveyToFront(survey)),
  tvd: body.tvd,
  displacement: body.displacement,
  easting: body.easting,
  holdLenSlant: body.hold_len_slant,
  holdLenVert: body.hold_len_vert,
  maxIncl: body.max_incl,
  linkedWithTarget: body.linked_with_target,
  northing: body.northing,
  isAutoHoldLenSlant: body.is_auto_hold_len_slant,
  isAutoHoldLenVert: body.is_auto_hold_len_vert,
  isAutoMaxIncl: body.is_auto_max_incl,
  isAutoBuildRate: body.is_auto_build_rate
});

export const jProfileCalcToBack = (body: JProfileFormType, profileCalcId: string): JProfileCalcCreateBackType => ({
  northing: body.northing,
  easting: body.easting,
  displacement: body.displacement,
  hold_len_slant: !body.isAutoHoldLenSlant ? body.holdLenSlant : null,
  hold_len_vert: !body.isAutoHoldLenVert ? body.holdLenVert : null,
  // Переворачиваем булевы значения так как в чекбоксах выбираются именно неизвестные параметры
  is_auto_build_rate: body.isAutoBuildRate,
  is_auto_hold_len_slant: body.isAutoHoldLenSlant,
  is_auto_hold_len_vert: body.isAutoHoldLenVert,
  is_auto_max_incl: body.isAutoMaxIncl,
  linked_with_target: body.linkedWithTarget,
  max_incl: !body.isAutoMaxIncl ? body.maxIncl : null,
  item_data: null,
  item_meta: null,
  linked_with_profile_calc: profileCalcId,
  azim: body.azim,
  build_rate: !body.isAutoBuildRate ? body.buildRate : null,
  tvd: body.tvd
});

export const sProfileCalcToFront = (body: SProfileCalcBackType): SProfileCalcType => ({
  azim: body.azim,
  itemData: body.item_data,
  itemMeta: body.item_meta,
  itemUuid: body.item_uuid,
  rowChangedBy: body.row_changed_by,
  rowChangedDatetime: body.row_changed_datetime,
  rowCreatedBy: body.row_created_by,
  rowCreatedDatetime: body.row_created_datetime,
  surveys: body.surveys.map(survey => calcSurveyToFront(survey)),
  tvd: body.tvd,
  buildRate1: body.build_rate_1,
  buildRate2: body.build_rate_2,
  displacement: body.displacement,
  easting: body.easting,
  holdLenVert: body.hold_len_vert,
  isAutoBuildRate1: body.is_auto_build_rate_1,
  isAutoBuildRate2: body.is_auto_build_rate_2,
  isAutoHoldLenVert: body.is_auto_hold_len_vert,
  isAutoMaxIncl1: body.is_auto_max_incl_1,
  isAutoMaxIncl2: body.is_auto_max_incl_2,
  isAutoStabLen1: body.is_auto_stab_len_1,
  isAutoStabLen2: body.is_auto_stab_len_2,
  linkedWithProfileCalc: body.linked_with_profile_calc,
  linkedWithTarget: body.linked_with_target,
  northing: body.northing,
  maxIncl1: body.max_incl_1,
  maxIncl2: body.max_incl_2,
  stabLen1: body.stab_len_1,
  stabLen2: body.stab_len_2
});

export const sProfileCalcToBack = (body: SProfileCalcFormType, profileCalcId: string): SProfileCalcCreateBackType => ({
  azim: body.azim,
  build_rate_1: body.isAutoBuildRate1 ? null : body.buildRate1,
  build_rate_2: body.isAutoBuildRate2 ? null : body.buildRate2,
  tvd: body.tvd,
  displacement: body.displacement,
  easting: body.easting,
  hold_len_vert: body.isAutoHoldLenVert ? null : body.holdLenVert,
  // Переворачиваем булевы значения так как в чекбоксах выбираются именно неизвестные параметры
  is_auto_build_rate_1: body.isAutoBuildRate1,
  is_auto_build_rate_2: body.isAutoBuildRate2,
  is_auto_hold_len_vert: body.isAutoHoldLenVert,
  is_auto_stab_len_1: body.isAutoStabLen1,
  is_auto_max_incl_1: body.isAutoMaxIncl1,
  is_auto_max_incl_2: body.isAutoMaxIncl2,
  is_auto_stab_len_2: body.isAutoStabLen2,
  item_data: null,
  item_meta: null,
  linked_with_profile_calc: profileCalcId,
  linked_with_target: body.linkedWithTarget,
  max_incl_1: body.isAutoMaxIncl1 ? null : body.maxIncl1,
  max_incl_2: body.isAutoMaxIncl2 ? null : body.maxIncl2,
  northing: body.northing,
  stab_len_1: body.isAutoStabLen1 ? null : body.stabLen1,
  stab_len_2: body.isAutoStabLen2 ? null : body.stabLen2
});

export const optimumAlignCalcToBack = (body: OptimumAlignFormType, profileCalcId: string): OptimumAlignCreateBackType => ({
  azim: body.azim,
  // @ts-ignore
  calc_type: body.calcType,
  tvd: body.tvd,
  displacement: body.displacement,
  easting: body.easting,
  dls_1: body.dls1,
  incl: body.incl,
  dls_2: body.dls2,
  item_data: null,
  item_meta: null,
  northing: body.northing,
  linked_with_profile_calc: profileCalcId,
  tangent_length: body.tangentLength,
  tvd_1: body.tvd1,
  tvd_2: body.tvd2
});

export const optimumAlignCalcToFront = (body: OptimumAlignBackType): OptimumAlignType => ({
  azim: body.azim,
  calcType: body.calc_type,
  dls1: body.dls_1,
  tvd: body.tvd,
  dls2: body.dls_2,
  easting: body.easting,
  displacement: body.displacement,
  incl: body.incl,
  itemData: body.item_data,
  itemMeta: body.item_meta,
  linkedWithProfileCalc: body.linked_with_profile_calc,
  northing: body.northing,
  linkedWithTarget: body.linked_with_target,
  tangentLength: body.tangent_length,
  tvd1: body.tvd_1,
  tvd2: body.tvd_2,
  itemUuid: body.item_uuid,
  rowChangedBy: body.row_changed_by,
  rowCreatedBy: body.row_created_by,
  rowCreatedDatetime: body.row_created_datetime,
  surveys: body.surveys.map(survey => calcSurveyToFront(survey)),
  profileType: body.profile_type,
  rowChangedDatetime: body.row_changed_datetime
});

export const trajectoryCalcToBack = (trajectoryCalc: ITrajectoryCalc): ITrajectoryCalcBack => ({
  well_uuid: trajectoryCalc.wellUuid,
  profile_calc_uuid: trajectoryCalc.profileCalcUuid,
  anticollision_calc_uuid: trajectoryCalc.anticollisionCalcUuid,
  row_created_datetime: trajectoryCalc.rowCreatedDatetime,
  row_created_by: trajectoryCalc.rowCreatedBy,
  row_changed_datetime: trajectoryCalc.rowChangedDatetime,
  row_changed_by: trajectoryCalc.rowChangedBy,
  item_data: trajectoryCalc.itemData,
  item_meta: trajectoryCalc.itemMeta,
  item_uuid: trajectoryCalc.itemUuid
});

export const anticollisionCalcToFront = (anticollisionCalc: IAnticollisionCalcBack): IAnticollisionCalc => ({
  itemData: anticollisionCalc.item_data,
  itemMeta: anticollisionCalc.item_meta,
  itemUuid: anticollisionCalc.item_uuid,
  rowChangedBy: anticollisionCalc.row_changed_by,
  rowChangedDatetime: anticollisionCalc.row_changed_datetime,
  rowCreatedBy: anticollisionCalc.row_created_by,
  rowCreatedDatetime: anticollisionCalc.row_created_datetime,
  errorSurfaceType: anticollisionCalc.error_surface_type,
  interpMdStep: anticollisionCalc.interp_md_step,
  linkedWithTrajectoryCalc: anticollisionCalc.linked_with_trajectory_calc,
  maxEllipseDist: anticollisionCalc.max_ellipse_dist,
  maxMinDist: anticollisionCalc.max_min_dist,
  maxSf: anticollisionCalc.max_sf,
  sigma: anticollisionCalc.sigma,
  wellsUuids: anticollisionCalc.offset_wells_uuids
});

export const anticollisionCalcToBack = (anticollisionCalc: IAnticollisionCalc): IAnticollisionCalcBack => ({
  offset_wells_uuids: anticollisionCalc.wellsUuids,
  sigma: anticollisionCalc.sigma,
  max_sf: anticollisionCalc.maxSf,
  max_min_dist: anticollisionCalc.maxMinDist,
  max_ellipse_dist: anticollisionCalc.maxEllipseDist,
  linked_with_trajectory_calc: anticollisionCalc.linkedWithTrajectoryCalc,
  interp_md_step: anticollisionCalc.interpMdStep,
  error_surface_type: anticollisionCalc.errorSurfaceType,
  row_created_datetime: anticollisionCalc.rowCreatedDatetime,
  row_created_by: anticollisionCalc.rowCreatedBy,
  row_changed_datetime: anticollisionCalc.rowChangedDatetime,
  row_changed_by: anticollisionCalc.rowChangedBy,
  item_data: anticollisionCalc.itemData,
  item_meta: anticollisionCalc.itemMeta,
  item_uuid: anticollisionCalc.itemUuid
});

export const fpFactToFront = (fp: ResultFactBackType): ResultFactType => ({
  northing: fp.northing,
  lrDisp: fp.lr_disp,
  abDisp: fp.ab_disp,
  tvd: fp.tvd,
  easting: fp.easting,
  azim: fp.azim,
  incl: fp.incl,
  md: fp.md
});

export const fpResultToFront = (fp:FpAnalysisResultBackType): FpAnalysisResultType => ({
  planResult: fp.plan_result,
  factResult: fpFactToFront(fp.fact_result),
  wellUuid: fp.well_uuid
});

export const fpAnalysisToFront = (fpAnalysis: FpAnalysisBackType): FpAnalysisType => ({
  result: fpResultToFront(fpAnalysis.result),
  rowChangedBy: fpAnalysis.row_changed_by,
  rowChangedDatetime: fpAnalysis.row_changed_datetime,
  rowCreatedBy: fpAnalysis.row_created_by,
  rowCreatedDatetime: fpAnalysis.row_created_datetime,
  itemData: fpAnalysis.item_data,
  itemMeta: fpAnalysis.item_meta,
  itemUuid: fpAnalysis.item_uuid,
  linkedWithFact: fpAnalysis.linked_with_fact,
  linkedWithPlan: fpAnalysis.linked_with_plan,
  linkedWithWell: fpAnalysis.linked_with_well
});

// export const fpAnalysisToBack = (fpAnalysis: FpAnalysisType): FpAnalysisBackType => ({
//     linked_with_well: fpAnalysis.linkedWithWell,
//     linked_with_plan: fpAnalysis.linkedWithPlan,
//     linked_with_fact: fpAnalysis.linkedWithFact,
//     item_data: fpAnalysis.itemData,
//     item_meta: fpAnalysis.itemMeta,
//     item_uuid: fpAnalysis.itemUuid,
//     result: fpAnalysis.result,
//     row_changed_by: fpAnalysis.rowChangedBy,
//     row_changed_datetime: fpAnalysis.rowChangedDatetime,
//     row_created_by: fpAnalysis.rowCreatedBy,
//     row_created_datetime: fpAnalysis.rowCreatedDatetime
// });